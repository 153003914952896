import * as React from "react";
import Countdown from "react-countdown";
import moment from "moment";
import Typography from "@mui/material/Typography";
import "moment/locale/es";

import { Number, Container } from "./styles";
export default function EventCountdown({ endDate }: { endDate: string }) {
  const date = moment(endDate, "DD-MM-YYYY hh:mm").locale("es").toDate();

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      // Render a completed state
      return <div />;
    } else {
      // Render a countdown
      return (
        <span>
          <Number>{days}</Number> {days === 1 ? "dia" : "dias"}{" "}
          <Number>{hours}</Number> {hours === 1 ? "hora" : "horas"}{" "}
          <Number>{minutes}</Number> minutos <Number>{seconds}</Number> segundos
        </span>
      );
    }
  };
  return (
    <Container>
      {/*  <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          lineHeight: 1
        }}
      >
        Faltan
      </Typography> */}
      <Countdown date={date} renderer={renderer} />
    </Container>
  );
}
