import React from "react";
import {Box, Grid} from "@mui/material";


export default function TerminosCondiciones() {
  return (
    <>
        <Grid container lg={8.85} md={10.5} xs={10.5}
          sx={{marginLeft: "auto",
          marginRight: "auto",
          paddingTop:"50px",
          paddingBottom: "172px",}}
        >
          <Box sx={{textAlign:"left"}}>
            <h1>POL&Iacute;TICA DE PRIVACIDAD Y CONDICIONES DE USO.</h1>
              <p>El presente contrato lleva impl&iacute;cito la pol&iacute;tica de privacidad y los t&eacute;rminos de uso de todo servicio y plataforma que regulan el uso por parte de los vecinos y vecina de la comuna de RANCAGUA (en adelante, &ldquo;<strong>USUARIOS</strong>&rdquo;) de la aplicaci&oacute;n inform&aacute;tica, sitio web y tarjeta vecino en su formato material y digital (en adelante, &ldquo;<strong>TARJETA VECINO RANCAGUA</strong>&rdquo;). Por medio de la tarjeta el usuario/a se registra, identiﬁca y acredita su calidad de vecino/a de la comuna de Rancagua, accediendo a los beneﬁcios provistos por la COMUNA DE RANCAGUA o por el comercio asociado, obteniendo informaci&oacute;n relevante de inter&eacute;s comunal.</p>
              <p>La App y sitio web proporcionar&aacute;n al usuario/a la informaci&oacute;n de beneﬁcios, convenios vigentes, invitaciones, notiﬁcaciones y promociones especiales que emanen de TARJETA VECINO RANCAGUA.</p>
              <p>El usuario que accede a la App, sitio web y/o Tarjeta declara conocer a aceptar los presentes T&eacute;rminos y Condiciones de uso, inequ&iacute;voca y suﬁcientemente de todas aquellas regulaciones y obligaciones que se se&ntilde;alan a continuaci&oacute;n, constituyendo una aprobaci&oacute;n perfecta e informada de cada una de las materias que en ella se indican. En caso de que el vecino/a no est&eacute; de acuerdo ni acepte los presentes T&eacute;rminos y Condiciones de uso, deber&aacute; abstenerse de acceder y utilizar la App, el sitio web y la Tarjeta, que se encuentran reguladas por los presentes T&eacute;rminos y Condiciones de Uso, y proceder a la desinstalaci&oacute;n de esta en su dispositivo m&oacute;vil y/u otra plataforma que corresponda.</p>
            <h1>ACCESO Y REGISTRO DE USUARIO.</h1>
              <p></p>
              <p>El usuario, deber&aacute; inscribirse debidamente en las formas que la TARJETA VECINO RANCAGUA disponga para estos efectos, siendo responsable de la veracidad de la informaci&oacute;n otorgada al momento de su inscripci&oacute;n y se compromete a entregar informaci&oacute;n ﬁdedigna. El acceso y uso de la App, sitio web y/o Tarjeta es gratuita para el usuario/a u otros usuarios que pudiese deﬁnir TARJETA VECINO RANCAGUA, a futuro, y s&oacute;lo para ser usada en el territorio de la Rep&uacute;blica de Chile.</p>
              <p>El acceso y uso de los servicios TARJETA VECINO RANCAGUA estar&aacute; sujeto, en todo caso, al previo registro del Usuario/a mediante el correspondiente Reglamento. Los datos introducidos por el Usuario/a en el Formulario de Registro y las plataformas de TARJETA VECINO RANCAGUA deber&aacute;n ser exactos, actuales y veraces en todo momento.</p>
              <p>El usuario declara ser mayor de edad y tener capacidad para entender y suscribir el presente contrato de uso, sus t&eacute;rminos y condiciones y suscribir lo relacionado a privacidad de la informaci&oacute;n y tratamiento de datos personales. Los menores de edad y personas en situaciones de discapacidad o aquellas establecidas en la ley requerir&aacute;n autorizaci&oacute;n de sus representantes legales para suscribir el presente contrato.</p>
              <p>El registro o inscripci&oacute;n del usuario en la App y/o sitio web, respectivamente, le otorga una clave o contrase&ntilde;a, que puede o no ser &uacute;nica e interoperable entre ellas, que servir&aacute; para la identiﬁcaci&oacute;n personal del usuario. Esta clave es personal e intransferible, y es responsabilidad del usuario/a la seguridad y cuidado en el resguardo de esta contrase&ntilde;a, la que no deber&aacute; dar a conocer a terceros en ninguna circunstancia, salvo previa autorizaci&oacute;n escrita de TARJETA VECINO RANCAGUA.</p>
              <p>En caso de olvido de la contrase&ntilde;a o cualquier otra circunstancia que suponga un riesgo de acceso y/o utilizaci&oacute;n por parte de terceros no autorizados, el usuario/a lo comunicar&aacute; inmediatamente a TARJETA VECINO RANCAGUA, siendo posible que el usuario/a podr&aacute; solicitar recuperar su clave o contrase&ntilde;a a trav&eacute;s de la misma App o sitio web.</p>
            <h1>USO DEL SERVICIO</h1>
              <p></p>
              <p>La Tarjeta Vecino Rancagua en su formato material y digital da derecho a los usuarios, debidamente inscritos y con registro vigente, para acceder y hacer uso de los beneﬁcios exclusivos contenidos en TARJETA VECINO RANCAGUA. Las formas y condiciones para hacer uso de los descuentos y beneﬁcios asociados a la Tarjeta sean c&oacute;digos de descuentos, promociones u otros de cualquier naturaleza, ser&aacute;n publicadas por TARJETA VECINO RANCAGUA por los canales que estime conveniente. Para hacer uso de los beneﬁcios informados precedentemente, el usuario deber&aacute; exhibir su Tarjeta Vecino Rancagua, sea en versi&oacute;n material o digital y adem&aacute;s, acreditar su identidad con su c&eacute;dula de identidad vigente.</p>
              <p>TARJETA VECINO RANCAGUA no participa como parte en ninguna relaci&oacute;n de consumo para efectos de lo establecido en la Ley N&deg; 19.496 de protecci&oacute;n de los derechos de los consumidores. La calidad y condiciones de los productos y/o servicios ofrecidos por el comercio asociado a TARJETA VECINO RANCAGUA, ser&aacute; de exclusiva responsabilidad de cada empresa. El Usuario se obliga a notiﬁcar a TARJETA VECINO RANCAGUA ante el conocimiento de irregularidades por parte del comercio asociado en la entrega de los</p>
              <p>beneﬁcios ofrecidos a trav&eacute;s de esta tarjeta. Los beneﬁcios entregados por terceros asociados a TARJETA VECINO RANCAGUA que se publiciten en la App, sitio web u otros medios de difusi&oacute;n, podr&aacute;n modiﬁcarse sin previo aviso al Usuario.</p>
              <p>TARJETA VECINO RANCAGUA no es responsable por los reclamos, p&eacute;rdidas, acciones, da&ntilde;os, demandas judiciales o procedimientos, producto de las interferencias de terceros en la provisi&oacute;n del servicio, o de aquellas que surjan o est&eacute;n relacionados de alguna manera con ocasi&oacute;n del acceso a los enlaces de sitios de terceros, sea cualquiera su naturaleza, o por ataques inform&aacute;ticos que afecten los datos e informaci&oacute;n del Usuario.</p>
              <p>Los t&eacute;rminos y condiciones propios de los beneﬁcios entregados y publicitados a trav&eacute;s de TARJETA VECINO RANCAGUA, sea a trav&eacute;s de la App, sitio web u otros medios, son de exclusiva responsabilidad del comercio asociado, s&oacute;lo entre el usuario y el oferente.</p>
            <h1>CONDICIONES T&Eacute;CNICAS DEL SERVICIO</h1>
              <p></p>
              <p>El Usuario permite a TARJETA VECINO RANCAGUA y al desarrollador de la App y sitio web, el acceso a los sistemas inform&aacute;ticos de su dispositivo donde se encuentre instalado o se ejecute la App, sitio web y/o Tarjeta, con la ﬁnalidad de realizar actualizaciones de software, resolver incidencias inform&aacute;ticas y realizar mejoras de funcionamiento.</p>
              <p>La App, sitio web y Tarjeta en su versi&oacute;n digital requieren conexi&oacute;n permanente a internet para su funcionamiento. Es responsabilidad del usuario contar con dicha conexi&oacute;n y estar habilitados para acceder a los beneﬁcios, productos y servicios. Estas conexiones podr&iacute;an verse afectadas por las caracter&iacute;sticas t&eacute;cnicas de la conexi&oacute;n a internet, los computadores, tel&eacute;fonos m&oacute;viles o celulares o cualquiera otro canal desde donde el usuario acceda a los servicios de TARJETA VECINO RANCAGUA.</p>
              <p>La App, sitio web y la Tarjeta versi&oacute;n digital ser&aacute;n objeto de actualizaciones permanente para mejorar el rendimiento y calidad del servicio entregado al Usuario/a, actualizaciones que pueden ser o no autom&aacute;ticas atendiendo las conﬁguraciones de la App, sitio web y Tarjeta versi&oacute;n digital, computadora, tel&eacute;fono m&oacute;vil o celular, u otras que corresponda, desde la cual el usuario hace uso de los servicios. El usuario es consiente en permitir las actualizaciones autom&aacute;ticas que procedan. Las condiciones de acceso y uso del usuario/a estar&aacute;n sujetas a la informaci&oacute;n provista por &eacute;l, de manera tal que, si esta es insuﬁciente para su funcionalidad, TARJETA VECINO RANCAGUA podr&aacute; condicionar, eliminar y/o</p>
              <p>cancelar el acceso y uso del usuario a la App, sitio web y/o Tarjeta, sea en su versi&oacute;n material o digital.</p>
              <p>El usuario comunicar&aacute; a TARJETA VECINO RANCAGUA la existencia de incidencias inform&aacute;ticas, entendiendo ellas como lentitud en la App, sitio web y/o Tarjeta, cierre inesperado de las mismas, y errores de similar naturaleza que afecte su uso.</p>
              <p>Previa autorizaci&oacute;n del usuario, y mientras la App est&eacute; en uso, TARJETA VECINO RANCAGUA podr&aacute; acceder a la localizaci&oacute;n y uso de c&aacute;mara u otra informaci&oacute;n relevante del equipo, con el objeto de mostrar los beneﬁcios y actividades en la actual localizaci&oacute;n del usuario, as&iacute; como el uso y consumo de estos, entregados por terceros asociados a TARJETA VECINO RANCAGUA. Esta autorizaci&oacute;n se requerir&aacute; por una vez y posteriormente el usuario podr&aacute; conﬁgurar en su m&oacute;vil si acepta o rechaza la localizaci&oacute;n de forma permanente.</p>
              <p>El usuario acepta que la inhabilitaci&oacute;n de sistemas y caracter&iacute;sticas puede afectar el funcionamiento de TARJETA VECINO RANCAGUA e impedir el acceso a ciertas funcionalidades, caracter&iacute;sticas y beneﬁcios que dependan de ellas.</p>
              <p>El usuario se hace responsable del extrav&iacute;o y/o mal uso de su contrase&ntilde;a para el acceso a la App o sitio web y acuerda notiﬁcar de inmediato a TARJETA VECINO RANCAGUA en caso de tener conocimiento de cualquier uso no autorizado a su sistema.</p>
              <p>TARJETA VECINO RANCAGUA no es responsable de las vulnerabilidades de seguridad, y las consecuencias de dichas vulnerabilidades respecto al tratamiento de datos personales u otras materias relacionadas, que surjan del uso no autorizado en sus productos o servicios, tales como virus, troyanos u otras rutinas de programaci&oacute;n, ni tampoco de la actualizaci&oacute;n o mantenimiento de los aparatos desde los cuales el usuario accede y hace uso de las plataformas provistas por la tarjeta. TARJETA VECINO RANCAGUA se reserva el derecho de actualizar la informaci&oacute;n contenida en la App, sitio web, y en la Tarjeta, sea versi&oacute;n material o digital, sean constitutivos de los presentes t&eacute;rminos y condiciones de uso o no sin mediar previo aviso al USUARIO. Las actualizaciones que procedan requerir&aacute;n la aceptaci&oacute;n del usuario/a para continuar haciendo uso de los servicios de TARJETA VECINO RANCAGUA.</p>
            <h1>PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES</h1>
              <p></p>
              <p>Los servicios de TARJETA VECINO RANCAGUA se basan en los siguientes principios de privacidad:</p>
              <p>Respetamos a nuestros usuarios y su privacidad. Cuidamos los datos que usamos y protegemos su uso. Especiﬁcamos que datos se usan y con que ﬁnalidad. Toda la informaci&oacute;n es an&oacute;nima resguardando los datos sensibles. Los usuarios pueden solicitar informaci&oacute;n del tratamiento de sus datos.</p>
              <p>TARJETA VECINO RANCAGUA, en conformidad con la ley N&deg; 19.628, sobre Protecci&oacute;n de la Vida Privada, almacena y trata datos personales de manera automatizada y anonimizada con el &uacute;nico ﬁn de publicitar y/o difundir materias relevantes relacionadas a beneﬁcios y servicios comunales y municipales, a trav&eacute;s de diversos medios, sean postal, email, tel&eacute;fono, redes sociales, plataformas m&oacute;viles u otras plataformas de difusi&oacute;n), as&iacute; como tambi&eacute;n, para informar de las actividades organizadas y/o promocionadas por la COMUNA DE RANCAGUA y/o TARJETA VECINO. La Municipalidad de Rancagua podr&aacute; hacer tratamiento de los datos personales para el cumplimiento de sus funciones legales, con los principios orientadores de coordinaci&oacute;n, eﬁciencia, transparencia y publicidad, todo dentro del &aacute;mbito de su competencia.</p>
              <p>El usuario es consiente en permitir a TARJETA VECINO RANCAGUA tratar directamente los datos y/ o transferir a instituciones p&uacute;blicas o privadas para el cumplimiento de los ﬁnes anteriormente descritos y para crear an&aacute;lisis estad&iacute;sticos y cient&iacute;ﬁcos, con prop&oacute;sitos de investigaci&oacute;n, desarrollo, elaboraci&oacute;n de pol&iacute;ticas p&uacute;blicas y an&aacute;lisis del servicio, o entregar a tribunales del pa&iacute;s en caso de ser requeridos en instancia judicial o administrativa, previo requerimiento en las formas establecidas por la ley.</p>
              <p>El usuario declara conocer el motivo y prop&oacute;sito del requerimiento de datos establecidos y autoriza a TARJETA VECINO RANCAGUA para almacenar, tratar y transferir los datos que se generar&aacute;n en virtud del presente contrato de uso de TARJETA VECINO RANCAGUA y declara conocer los ﬁnes para los cuales han sido requeridos y ser&aacute;n utilizados. Los datos proporcionados se conservar&aacute;n mientras no solicite el cese de su custodia o se ponga t&eacute;rmino al presente contrato de uso de servicios TARJETA VECINO RANCAGUA y ser&aacute;n eliminados cuando ya no sean necesarios para los ﬁnes que fueron requeridos.</p>
              <p>TARJETA VECINO RANCAGUA cree y promociona el uso responsable de los datos personales de sus usuarios, para lo cual garantiza los siguientes derechos:</p>
              <p>Derecho de acceso: El usuario/a podr&aacute; solicitar y obtener conﬁrmaci&oacute;n sobre el tratamiento de sus datos, accediendo a los datos y su origen; su ﬁnalidad; sus destinatarios; el periodo de tiempo durante el cual ser&aacute;n tratados, sin perjuicio de las excepciones establecidas en la ley.</p>
              <p>Derecho de rectiﬁcaci&oacute;n: El usuario/a podr&aacute; solicitar la rectiﬁcaci&oacute;n de sus datos cuando sean inexactos, desactualizados o incompletos.</p>
              <p>Derecho de cancelaci&oacute;n y destrucci&oacute;n: El usuario/a podr&aacute; solicitar la cancelaci&oacute;n y destrucci&oacute;n de los datos, en virtud de las causales establecidas en la ley.</p>
              <p>Derecho de oposici&oacute;n: El usuario/a podr&aacute; solicitar que no se realice un tratamiento determinado cuando afecte sus derechos y libertades fundamentales y dem&aacute;s causas establecidas en la ley.</p>
              <p>Derecho de portabilidad: Derecho a obtener una copia de los datos personales de manera estructurada, en formato gen&eacute;rico y de uso com&uacute;n, en virtud de las causales establecidas en la ley.</p>
              <p>TARJETA VECINO RANCAGUA se compromete a recibir y gestionar los requerimientos de informaci&oacute;n, modiﬁcaci&oacute;n o eliminaci&oacute;n de datos personales del usuario/a, para proceder a informar, modiﬁcar o eliminar lo solicitado. Para todos los efectos, los requerimientos y respuestas ser&aacute;n de cargo y responsabilidad de la Comuna de Rancagua, a trav&eacute;s de su departamento de Oﬁcina de Atenci&oacute;n al Vecino, cuyo correo de contacto es atenci&oacute;n es <a href="mailto:soporte@innovarancagua.cl">soporte@innovarancagua.cl</a></p>
              <p>El usuario/a entiende y consiente en que el ejercicio del derecho a modiﬁcar, cancelar o destruir datos puede acarrear la terminaci&oacute;n o cancelaci&oacute;n de los servicios provistos por TARJETA VECINO RANCAGUA producto de incapacidades e inviabilidades t&eacute;cnicas para su funcionamiento.</p>
            <h1>ACEPTACI&Oacute;N DEL CONTRATO Y T&Eacute;RMINOS Y CONDICIONES DE USO: POL&Iacute;TICAS DE PRIVACIDAD TARJETA VECINO RANCAGUA.</h1>
              <p>El usuario/a declara haber conocido, le&iacute;do y entendido el presente contrato y t&eacute;rminos y condiciones de uso y pol&iacute;ticas de privacidad de los servicios de TARJETA VECINO RANCAGUA, respecto a los cuales acepta y entrega su consentimiento de manera libre, informada, espec&iacute;ﬁca e inequ&iacute;voca.</p>
          </Box>
        </Grid>
    </>
  );
}