import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize("G-XGY8YC5D7W");
};

export const logPageView = (custom = "") => {
  const page = custom && custom !== "" ? custom : window.location.pathname;
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

export const logEvent = (category = "", action = "") => {
  if (category && action) {
    ReactGA.event({ category, action });
  }
};

export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
