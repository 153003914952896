import React, { useState, Fragment, useEffect } from "react";
import { Box, Grid, Button } from "@mui/material";
import { saveAs } from "file-saver";
import { initGA, logPageView, logEvent } from "helpers/analytics";
/* import CorpLogo from 'components/CorpLogo' */

const Ticket = (props) => {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  return (
    <div>
      <Grid container sx={{ textAlign: "left" }}>
        <img
          className="img-fluid"
          src="https://firebasestorage.googleapis.com/v0/b/rancagua-digital-app.appspot.com/o/tickets%2Fticket-safari.svg?alt=media&token=dae794e4-17e0-4aef-a38e-908e72ead28e"
          alt="entrada safari"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Button
          variant="contained"
          style={{ marginBottom: "1rem" }}
          onClick={() => {
            saveAs(
              "https://firebasestorage.googleapis.com/v0/b/rancagua-digital-app.appspot.com/o/tickets%2Fticket-safari.png?alt=media&token=4f758522-196d-4b9a-859c-231df549b62d",
              "ticket"
            );
            logEvent("download_ticket", "ticket_safari");
          }}
        >
          Descargar
        </Button>
      </Grid>
    </div>
  );
};

export default Ticket;
