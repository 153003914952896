import React from "react";
//Components
import { CircularProgress, Typography, Box } from "@mui/material";

interface LoadingProps {
  text?: string;
}

export default function Loading({ text = "Cargando" }: LoadingProps) {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress
            color="primary"
            size={60}
            style={{ marginBottom: 20 }}
          />
          <Typography variant="h6">{text}</Typography>
        </div>
      </Box>
    </>
  );
}
