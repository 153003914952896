import axios from "axios";

export interface Votes {
  option: string;
  count: number;
}

const SERVICE_URL = "https://services-moleculer.smartrancagua.com/votes/count";
export async function getVotesData(): Promise<Votes[]> {
  try {
    const { data } = await axios.get(SERVICE_URL);
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
}
