export const getCarousel = () => [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/cdir-web.appspot.com/o/cdir%2Fbanners%2Fcarrousel%2Fportada-web-1.png?alt=media&token=6368033d-c194-4ebc-b6db-fb28da0a08d8",
    src2: "https://firebasestorage.googleapis.com/v0/b/cdir-web.appspot.com/o/cdir%2Fbanners%2Fcarrousel%2Fportada-mobile%201.png?alt=media&token=6730b054-fd26-4f52-a1d0-55ab6dd1386d",
    url: "https://innovarancagua.cl/noticias/fondo-concursable-innova-rancagua",
    alt: "Fondo Concursable"
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/cdir-web.appspot.com/o/cdir%2Fbanners%2Fportada-web.png?alt=media&token=1341d7d8-9216-44d1-ab02-76c017949bb8",
    src2: "https://firebasestorage.googleapis.com/v0/b/cdir-web.appspot.com/o/cdir%2Fbanners%2Fportada-mobile.png?alt=media&token=f4301a47-7ef7-4925-a7b1-ead9c7de4b1a",
    url: "https://firebasestorage.googleapis.com/v0/b/cdir-web.appspot.com/o/cdir%2Fbanners%2Fimagen-web.png?alt=media&token=bca62205-b58f-44b1-8fc4-acf50de487e7",
    alt: "Rancagua Ético"
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fcarousel%2F1.webp?alt=media&token=f2901fa6-f0cd-4161-8781-240d98958ac4",
    src2: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fcarousel%2F1-mobile.webp?alt=media&token=ef19d76e-6f9d-4a6c-a512-c14bca371782",
    url: "proyectos/innovacion-social",
    alt: "innovacion social"
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fcarousel%2F2.webp?alt=media&token=8de551ed-f4ed-4e59-9ef1-851939d42a1f",
    src2: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fcarousel%2F2-mobile.webp?alt=media&token=7a3510e0-b212-4d43-832f-a996cf27f033",
    url: "proyectos/generando-identidad",
    alt: "generando identidad"
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fcarousel%2F3.webp?alt=media&token=bad0a9a2-bb24-4110-bca4-0fae0a70a40a",
    src2: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fcarousel%2F3-mobile.webp?alt=media&token=2e2d7dd4-5a62-41e2-a6ec-bc71c689337a",
    url: "proyectos/seminarios-y-capacitaciones",
    alt: "seminarios y capacitaciones"
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fcarousel%2F4.webp?alt=media&token=f005317e-741d-49ff-8ca5-8431994c3b22",
    src2: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fcarousel%2F4-mobile.webp?alt=media&token=635daccb-94d9-4a64-ae3f-2e9b86de7770",
    url: "proyectos/desarrollo-tecnologías-informacion",
    alt: "desarrollo"
  }
];
