import React, { useEffect, useState } from "react";
//Components
import { Button, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { styled } from "@mui/material/styles";

const SecondLineText = styled("span")(({ theme }) => ({
  display: "block",
  textDecoration: "none",
  color: "black",
}));
const FirstLineText = styled("span")(({ theme }) => ({
  color: "black",
  textDecoration: "none",
  bold: "true",
}));

const SecondLineTextM = styled("span")(({ theme }) => ({
  display: "block",
  textDecoration: "none",
  color: "black",
}));
const FirstLineTextM = styled("span")(({ theme }) => ({
  color: "black",
  textDecoration: "none",
  bold: "true",
}));

export default function Laws() {
  const [renderMobile, setRenderMobile] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderMobile(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div style={{ zIndex: 1, position: "relative" }}>
        <Stack
          spacing={20}
          direction="row"
          sx={{
            paddingTop: "172px",
            paddingBottom: "172px",
            justifyContent: "center",
            display: { xs: "none", md: "none", lg: "flex" },
          }}
        >
          <Button
            variant="outlined"
            size="large"
            href="https://www.portaltransparencia.cl/PortalPdT/directorio-de-organismos-regulados/?org=CM060"
            target="_blank"
            sx={{
              borderColor: "#111212",
              borderRadius: "0",
              ":hover": {
                color: "white",
                backgroundColor: "#F1F1F1",
                borderColor: "black",
              },
            }}
          >
            <Stack direction="column" alignItems="center">
              <FirstLineText>Solicitud de Información</FirstLineText>
              <SecondLineText>Ley de Transparencia</SecondLineText>
            </Stack>
          </Button>
          <Button
            variant="outlined"
            size="large"
            href="https://www.portaltransparencia.cl/PortalPdT/directorio-de-organismos-regulados/?org=CM060"
            target="_blank"
            sx={{
              borderColor: "#111212",
              borderRadius: "0",
              ":hover": {
                color: "white",
                backgroundColor: "#F1F1F1",
                borderColor: "black",
              },
            }}
          >
            <Stack direction="column" alignItems="center">
              <FirstLineText>Transparencia Activa</FirstLineText>
              <SecondLineText>Ley de Transparencia</SecondLineText>
            </Stack>
          </Button>
          <Button
            variant="outlined"
            size="large"
            href="https://www.leylobby.gob.cl/instituciones/CM060"
            target="_blank"
            sx={{
              borderColor: "#111212",
              borderRadius: "0",
              ":hover": {
                color: "white",
                backgroundColor: "#F1F1F1",
                borderColor: "black",
              },
            }}
          >
            <Stack direction="column" alignItems="center">
              <FirstLineText>Solicitar Información</FirstLineText>
              <SecondLineText>Ley de Lobby</SecondLineText>
            </Stack>
          </Button>
        </Stack>
        {/* MOBILE */}
        {renderMobile && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              spacing={7}
              direction="column"
              sx={{
                paddingTop: "118px",
                paddingBottom: "118px",
                justifyContent: "center",
                display: { xs: "flex", md: "flex", lg: "none" },
              }}
            >
              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderColor: "#111212",
                  borderRadius: "0",
                  ":hover": {
                    color: "white",
                    backgroundColor: "#F1F1F1",
                    borderColor: "black",
                  },
                }}
              >
                <Stack direction="column" alignItems="center">
                  <FirstLineTextM>Solicitud de Información</FirstLineTextM>
                  <SecondLineTextM>Ley de Transparencia</SecondLineTextM>
                </Stack>
              </Button>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderColor: "#111212",
                  borderRadius: "0",
                  ":hover": {
                    color: "white",
                    backgroundColor: "#F1F1F1",
                    borderColor: "black",
                  },
                }}
              >
                <Stack direction="column" alignItems="center">
                  <FirstLineTextM>Transparencia Activa</FirstLineTextM>
                  <SecondLineTextM>Ley de Transparencia</SecondLineTextM>
                </Stack>
              </Button>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderColor: "#111212",
                  borderRadius: "0",
                  ":hover": {
                    color: "white",
                    backgroundColor: "#F1F1F1",
                    borderColor: "black",
                  },
                }}
              >
                <Stack direction="column" alignItems="center">
                  <FirstLineTextM>Solicitar Información</FirstLineTextM>
                  <SecondLineTextM>Ley de Lobby</SecondLineTextM>
                </Stack>
              </Button>
            </Stack>
          </Grid>
        )}
      </div>
    </>
  );
}
