import React, { useState, useEffect } from "react";
//Components
import { Box, Grid, IconButton, Link, Typography } from "@mui/material";
import Loading from "../../components/Loading";

//Providers
import { ProjectsNew, projectsData } from "../../providers/projects";
import { initGA, logPageView } from "../../helpers/analytics";

//Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function Proyectos() {
  const [project, setProject] = useState<ProjectsNew[]>([]);
  const [projectid, setProjectid] = useState<ProjectsNew | "">();
  const [isLoading, setIsLoading] = useState(true);

  async function preloadImages(imageUrls: string[]) {
    const promises = imageUrls.map((url) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve();
        img.onerror = reject;
      });
    });
    await Promise.all(promises);
  }

  useEffect(() => {
    async function fetchData() {
      const newsData = await projectsData();
      setProject(newsData);

      // Preload images
      const imageUrls = newsData.map((data) => data.categoryImage.src);
      await preloadImages(imageUrls);

      setIsLoading(false);
    }
    fetchData();
  }, []);
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            "@media screen and (max-width: 767px)": {
              // Estilo para xs
              paddingBottom: "149px",
            },
            "@media screen and (min-width: 768px)": {
              // Estilo para md
              paddingBottom: "293px",
            },
          }}
        >
          {project.map((data, index) => (
            <Grid
              container
              key={index}
              style={{
                flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              }}
            >
              <Grid item xs={12} md={12} lg={6}>
                <Link href={`/proyectos/${data.urlKey}`}>
                  <img
                    src={data.categoryImage.src}
                    alt={data.categoryImage.title}
                    style={{
                      width: "100%",
                      minHeight: "100%",
                    }}
                  />
                </Link>
              </Grid>
              {/* Mobile */}
              <Grid
                item
                xs={10.5}
                md={10.5}
                sx={{
                  display: { xs: "flex", md: "flex", lg: "none" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Link
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                  href={`/proyectos/${data.urlKey}`}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      paddingBottom: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {data.title}
                    <IconButton
                      aria-label="Ver más"
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Typography>
                </Link>
              </Grid>
              {/* Desktop */}
              <Grid
                sx={{ display: { xs: "none", md: "none", lg: "flex" } }}
                item
                xs={12}
                md={12}
                lg={6}
                container
                alignItems="center"
              >
                <Grid sx={{ marginBottom: "-400px" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "47px",
                      fontWeight: "700",
                      paddingLeft: "94px",
                      marginLeft: index % 2 === 0 ? "" : "138px",
                      textAlign: "left",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {data.title.includes("y") ? (
                      <>
                        {data.title.slice(0, data.title.indexOf("y") + 1)}
                        <div style={{ display: "block" }}>
                          {data.title.slice(data.title.indexOf("y") + 1)}
                        </div>
                      </>
                    ) : (
                      data.title
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Link
                    sx={{
                      textTransform: "none",
                      textDecoration: "none",
                      color: "inherit",
                    }}
                    href={`/proyectos/${data.urlKey}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        style={{
                          fontSize: "25px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          marginRight: "10px",
                          paddingTop: "25%",
                          marginLeft: index % 2 === 0 ? "474px" : "574px",
                        }}
                      >
                        Ver proyecto
                      </Typography>
                      <IconButton
                        aria-label="Ver más"
                        sx={{
                          paddingTop: "25%",
                          textTransform: "none",
                          textDecoration: "none",
                          color: "inherit",
                          "&:hover, &:active, &:focus, &:focus-visible": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            outline: "none",
                          },
                        }}
                      >
                        <ArrowForwardIcon sx={{ color: "#111212" }} />
                      </IconButton>
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </>
  );
}
