import React, { useState, useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Typography, Link } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Lightbox from "yet-another-react-lightbox";
import { doc } from "firebase/firestore";
import { useFirestoreDocData, useFirestore } from "reactfire";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import "yet-another-react-lightbox/styles.css";

//Components
import Loading from "components/Loading";
import Title from "components/Title";
import { ProjectsDetailsDescriptionSanitize } from "components/SanitizeHTML";
import Paragraph from "components/Paragraph";

//Providers
import { initGA, logPageView } from "helpers/analytics";
import { getProjectDataDetail, ProjectsAll } from "providers/projects";

import HistoriaEnTusManos from "landings/HistoriaEnTusManos";

export default function ProyectosDetalleEspecifico() {
  /* const [landingData, setLandingData] = useState<any>(null); */
  const [landing, setLanding] = useState<any>(null);
  const { projectId } = useParams(); // TODO: change to project id
  const [projectDataDetail, setProjectDataDetail] =
    useState<ProjectsAll | null>(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [bannerLoaded, setBannerLoaded] = useState(false);

  const urlKey = projectId || "";
  const customLandingRef = doc(
    useFirestore(),
    "custom-landing",
    urlKey.toString()
  );

  const { status, data } = useFirestoreDocData(customLandingRef);

  useEffect(() => {
    async function loadLanding(data: any) {
      try {
        const { pathName } = data;
        /* const View = await importLanding(pathName); */
        console.log("pathName", pathName);
        if (pathName === "landings/HistoriaEnTusManos") {
          setLanding(data);
        }
        /* setLanding(<View key={urlKey} data={data} />); */
      } catch (error) {
        console.log("[loadLanding] error", error);
      }
    }
    // TODO: Add global loading
    if (status === "success" && data && projectDataDetail?.customLanding) {
      loadLanding({ ...data, project: projectDataDetail });
    }
  }, [status, data, urlKey, projectDataDetail]);

  useEffect(() => {
    async function fetchData() {
      const projectDetail = await getProjectDataDetail(projectId);
      const data: any = {
        urlKey: projectDetail?.urlKey || "",
        title: projectDetail?.title || "",
        description: projectDetail?.description || "",
        publicationDate: projectDetail?.publicationDate || "",
        banner: {
          src: projectDetail?.banner?.src || "",
          title: projectDetail?.banner?.title || ""
        },
        previewImage: {
          src: projectDetail?.previewImage?.src || "",
          title: projectDetail?.previewImage?.title || ""
        },
        customLanding: projectDetail?.customLanding || false,
        gallery: projectDetail?.gallery || [],
        video: projectDetail?.video || "",
        externalUrl: projectDetail?.externalUrl || []
      };
      setProjectDataDetail(data);
    }
    fetchData();
  }, [projectId]);

  useEffect(() => {
    // load assets
    if (projectDataDetail) {
      if (projectDataDetail.gallery.length === 0) {
        setImagesLoaded(true);
        return;
      }
      const imagePromises = projectDataDetail.gallery.map((imagen) => {
        return new Promise<void>((resolve) => {
          const img = new Image();
          img.src = `${imagen.src}?w=164&h=164&fit=crop&auto=format`;
          img.onload = () => resolve();
        });
      });

      Promise.all(imagePromises).then(() => {
        setImagesLoaded(true);
      });
    }

    if (projectDataDetail?.banner.src) {
      const bannerImage = new Image();
      bannerImage.src = projectDataDetail.banner.src;
      bannerImage.onload = () => {
        setBannerLoaded(true);
      };
    } else {
      setBannerLoaded(true);
    }
  }, [projectDataDetail]);

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }, []);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  function extractYouTubeVideoId(url: string) {
    try {
      /* const match = url.match(/youtube\.com\/watch\?v=(.*)/);
      if (match && match[1]) {
        return match[1];
      } else {
        return url.includes("youtube.com/embed/")
          ? url.split("/embed/")[1]
          : null;
      } */
      return null;
    } catch (error) {
      console.error("error youtube", error);
      return null;
    }
  }
  const lightboxImages = projectDataDetail?.gallery.map((imagen) => ({
    src: imagen.src,
    alt: projectDataDetail?.title
  }));

  console.log("projectDataDetail", projectDataDetail);

  // TODO: render custom landing
  return !projectDataDetail ||
    !imagesLoaded ||
    !bannerLoaded ||
    status === "loading" ? (
    <Loading />
  ) : projectDataDetail?.customLanding ? (
    <div>
      <Suspense fallback={<span>cargando...</span>}>
        <HistoriaEnTusManos data={landing} />
      </Suspense>
    </div>
  ) : (
    <>
      <Grid
        lg={8.75}
        md={10.5}
        xs={10.5}
        container
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "left"
        }}
      >
        {projectDataDetail.banner.src && (
          <Grid lg={12}>
            <Box
              sx={{
                paddingTop: "0"
              }}
            >
              {/*  <Title>{projectDataDetail.title}</Title> */}

              <img
                src={projectDataDetail.banner.src}
                alt={projectDataDetail.banner.title}
                style={{
                  width: "100%" /* isMobile ? "100%" : "1375px" */,
                  height: "auto" /* isMobile ? "auto" : "707px" */
                  /* minHeight: !isMobile ? "707px" : "auto", */
                  /*  border: "1px solid red" */
                }}
              />
            </Box>
          </Grid>
        )}
        <Grid lg={12}>
          <Box
            sx={{
              paddingTop: "58px"
            }}
          >
            <Title>{projectDataDetail.title}</Title>
          </Box>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Typography
            variant="subtitle2"
            sx={{
              "@media screen and (max-width: 767px)": {
                // Estilo para xs
                fontSize: "14px"
              },
              "@media screen and (min-width: 768px)": {
                // Estilo para md
                fontSize: "18px"
              },
              fontWeight: "400",
              color: "#757779"
            }}
          >
            {projectDataDetail?.publicationDate.substring(0, 10)}
          </Typography>
        </Grid>
        <Grid lg={12}>
          <ProjectsDetailsDescriptionSanitize
            description={projectDataDetail?.description || ""}
          />
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Grid lg={12} md={12} xs={12}>
            <div
              style={{
                width: "100%",
                paddingTop: "77px"
              }}
            >
              <ImageList
                cols={isMobile ? 2 : 3}
                rowHeight={isMobile ? 200 : 450}
              >
                {projectDataDetail?.gallery.length > 0 &&
                  projectDataDetail?.gallery.map((imagen, index) => (
                    <ImageListItem key={index}>
                      <img
                        src={`${imagen.src}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${imagen.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={projectDataDetail?.title}
                        loading="lazy"
                        onClick={() => {
                          setLightboxOpen(true);
                          setSelectedImageIndex(index);
                        }}
                      />
                    </ImageListItem>
                  ))}
              </ImageList>
            </div>
          </Grid>
        </Grid>
        {/* {projectDataDetail?.video && (
          <Grid lg={12}>
            <Box sx={{ paddingTop: "110px" }}>
              <iframe
                width={isMobile ? 360 : 917}
                height={isMobile ? 218.564 : 558}
                src={
                  projectDataDetail?.video.includes("youtube.com/embed/")
                    ? projectDataDetail.video
                    : `https://www.youtube.com/embed/${extractYouTubeVideoId(
                        projectDataDetail?.video
                      )}`
                }
                title={projectDataDetail?.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Box>
          </Grid>
        )} */}
        {projectDataDetail?.externalUrl &&
          projectDataDetail?.externalUrl.length > 0 && (
            <Grid lg={12} md={12} xs={12}>
              <Box
                sx={{
                  paddingTop: "108px"
                }}
              >
                <Title>Enlaces de interés</Title>
              </Box>
            </Grid>
          )}

        {projectDataDetail?.externalUrl.map((external, index) => (
          <Grid lg={12}>
            <div style={{ width: "100%", paddingTop: "16px" }}>
              <ImageList
                cols={isMobile ? 1 : 3}
                rowHeight={isMobile ? 200 : 450}
              >
                <Link
                  href={external.url}
                  target="_blank"
                  sx={{ textTransform: "none", textDecoration: "none" }}
                >
                  <ImageListItem>
                    <img
                      src={external.image.src}
                      srcSet={external.image.src}
                      alt={external.image.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                </Link>
              </ImageList>
              <Link
                href={external.url}
                target="_blank"
                sx={{ textTransform: "none", textDecoration: "none" }}
              >
                <Box
                  sx={{
                    fontWeight: "700",
                    paddingTop: "22px",
                    paddingBottom: "169px",
                    color: "black"
                  }}
                >
                  <Paragraph>{external.title}</Paragraph>
                </Box>
              </Link>
            </div>
          </Grid>
        ))}
      </Grid>
      {lightboxOpen && (
        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={lightboxImages}
        />
      )}
    </>
  );
}
