import React, { useEffect } from "react";
//components
import { Grid, Box } from "@mui/material";
import Title from "../../components/Title";
import Paragraph from "../../components/Paragraph";
import { initGA, logPageView } from "../../helpers/analytics";

export default function LaCorporacion() {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  return (
    <>
      <Grid container sx={{ textAlign: "left" }}>
        <Box sx={{ display: { xs: "none", md: "none", lg: "flex" } }}>
          <Grid xs={12} md={12} lg={8}>
            <Grid
              lg={6.4}
              sx={{
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <Box
                sx={{
                  paddingBottom: "29px",
                  paddingTop: "41px"
                }}
              >
                <Title>Historia</Title>
              </Box>
              <Box
                sx={{
                  lineHeight: "28px",
                  paddingBottom: "-10px"
                }}
              >
                <Paragraph>
                  La Corporación de Desarrollo e Innovación de Rancagua es una
                  entidad pública-privada creada el 20 de junio de 2017, tras la
                  aprobación unánime del Concejo Municipal de la Ilustre
                  Municipalidad de Rancagua. Su objetivo principal es promover,
                  fomentar, coordinar y ejecutar proyectos para el desarrollo
                  económico, productivo y social de la ciudad, a través de la
                  Innovación y la Seguridad Pública.
                  <br />
                  <br />
                  Desde su creación, CDIR ha trabajado en diversas iniciativas y
                  proyectos que abarcan la innovación social, seguridad,
                  tecnología, emprendimiento, fomento, medio ambiente, equidad
                  de género, entre otros ámbitos, para mejorar la calidad de
                  vida de las y los vecinos de Rancagua.
                  <br />
                  <br />
                  CDIR dispone de un presupuesto anual dividido en tres
                  programas principales: Administración, RR.HH. y Operación;
                  Desarrollo e Innovación; y Seguridad. Estos programas están
                  orientados a fomentar la inversión, la seguridad y la
                  colaboración intersectorial para construir una mejor ciudad.
                </Paragraph>
              </Box>
            </Grid>
          </Grid>
          <Grid xs={12} md={12} lg={6} sx={{ marginBottom: "-10px" }}>
            <img
              src="/la_corporacion.png"
              style={{
                width: "864px",
                height: "707px"
              }}
            />
          </Grid>
        </Box>
        {/* MOBILE */}
        <Box sx={{ display: { xs: "flex", md: "flex", lg: "none" } }}>
          <Grid xs={12} md={12} lg={8}>
            <Grid
              sx={{
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <Grid xs={12} md={12}>
                <img
                  src="/la_corporacion.png"
                  style={{ width: "100%", height: "371px" }}
                />
              </Grid>
              <Grid
                xs={10.5}
                md={10.5}
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              >
                <Box
                  sx={{
                    lineHeight: "28px",
                    paddingBottom: "52px"
                  }}
                >
                  <Box
                    sx={{
                      paddingBottom: "29px",
                      paddingTop: "41px"
                    }}
                  >
                    <Title>Historia</Title>
                  </Box>
                  <Paragraph>
                    La Corporación de Desarrollo e Innovación de Rancagua es una
                    entidad pública-privada creada el 20 de junio de 2017, tras
                    la aprobación unánime del Concejo Municipal de la Ilustre
                    Municipalidad de Rancagua. Su objetivo principal es
                    promover, fomentar, coordinar y ejecutar proyectos para el
                    desarrollo económico, productivo y social de la ciudad, a
                    través de la Innovación y la Seguridad Pública.
                    <br />
                    <br />
                    Desde su creación, CDIR ha trabajado en diversas iniciativas
                    y proyectos que abarcan la innovación social, seguridad,
                    tecnología, emprendimiento, fomento, medio ambiente, equidad
                    de género, entre otros ámbitos, para mejorar la calidad de
                    vida de las y los vecinos de Rancagua.
                    <br />
                    <br />
                    CDIR dispone de un presupuesto anual dividido en tres
                    programas principales: Administración, RR.HH. y Operación;
                    Desarrollo e Innovación; y Seguridad. Estos programas están
                    orientados a fomentar la inversión, la seguridad y la
                    colaboración intersectorial para construir una mejor ciudad.
                  </Paragraph>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ backgroundColor: "#b73c8e", width: "100%" }}>
          <Grid
            xs={10.5}
            md={10.5}
            lg={9}
            sx={{
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <Box
              sx={{
                color: "#FFFFFF",
                paddingTop: { lg: "196px", xs: "41px", md: "41px" }
              }}
            >
              <Title>Misión</Title>
            </Box>
            <Box
              sx={{
                color: "#FFFFFF",
                paddingTop: "21px"
              }}
            >
              <Paragraph>
                Mejorar la calidad de vida de los vecinos de la comuna,
                atendiendo sus necesidades a través de proyectos sociales,
                económicos y urbanos en colaboración con el sector privado y la
                participación ciudadana. Así, la Corporación actúa como un ente
                articulador entre la Ilustre Municipalidad de Rancagua, los
                organismos públicos y el sector privado.
              </Paragraph>
            </Box>
          </Grid>
          <Grid
            xs={10.5}
            md={10.5}
            lg={9}
            sx={{
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <Box
              sx={{
                color: "#FFFFFF",
                paddingTop: "72px"
              }}
            >
              <Title>Visión</Title>
            </Box>
            <Box
              sx={{
                color: "#FFFFFF",
                paddingTop: "21px",
                paddingBottom: { lg: "196px", xs: "41px", md: "41px" }
              }}
            >
              <Paragraph>
                Ser una Corporación innovadora y de excelencia a nivel regional
                y nacional, transparente y con altos estándares de calidad, que
                logre un crecimiento y desarrollo sostenido mediante la mejora
                continua y se convierta en un referente para instituciones
                similares en el país.
              </Paragraph>
            </Box>
          </Grid>
        </Box>
        <Grid
          xs={10.5}
          md={10.5}
          lg={9}
          sx={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Box sx={{ paddingTop: { lg: "187px", xs: "41px", md: "41px" } }}>
            <Title>Objetivos</Title>
          </Box>
          <Box
            sx={{
              paddingTop: "21px",
              lineHeight: "28px",
              paddingBottom: "349px"
            }}
          >
            <Paragraph>
              De acuerdo con los Estatutos de la Corporación de Desarrollo e
              Innovación, su objetivo general es:
              <br />
              <ul>
                <li>
                  Estudiar, desarrollar y promover la economía creativa local.
                </li>
                <li>
                  Promover, fomentar, coordinar y ejecutar proyectos para la
                  innovación productiva, incluyendo emprendimiento, desarrollo e
                  innovación en procesos productivos y de servicios, así como
                  transferencia tecnológica en la región.
                </li>
                <li>
                  Fomentar, coordinar y difundir temas relacionados con la
                  seguridad ciudadana en la comuna.
                </li>
                <li>
                  Promover y desarrollar proyectos educativos a todos los
                  niveles.
                </li>
                <li>
                  Estudiar, desarrollar y ejecutar proyectos tecnológicos y
                  energéticos.
                </li>
                <li>
                  Promover y desarrollar el turismo local, regional e
                  internacional.
                </li>
              </ul>
            </Paragraph>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
