import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Link, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { gapi } from "gapi-script";
import _get from "lodash/get";
import "./styles.css";

const CLIENT_ID =
  "426438953004-8lttrj9u44qn0t1ebc43qppacu9iph7m.apps.googleusercontent.com";
//const API_KEY = "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";
const API_KEY = "AIzaSyAboaNY0r5q393FutUFQrVSUv-zl2Wk9yk";
const SPREADSHEET_ID = "1ZSXD61T2clbS8g2fPg0asfV5ovllbraQKhc1Ds4GGfc";
const SHEET_NAME = "inventario";
const COLUMNS = [
  "ITEM",
  "NOMBRE ACTIVO",
  "CODIGO",
  "CODIGO BARRA",
  "CANTIDAD",
  "MEDIDAS",
  "FABRICANTE",
  "CATEGORIA",
  "UBICACIÓN",
  "PROCEDENCIA",
  "DESCRIPCIÓN",
  "VALORIZACIÓN ACTIVO",
  "Nº FACTURA/BOLETA ELECT.",
  "VALOR FACTURADO UNIDAD"
];
type RowData = (string | number)[];
export default function InventoryPage() {
  const [searchParams] = useSearchParams();
  console.log("searchParams", searchParams.get("code"));
  const [data, setData] = useState<RowData | null>(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [rowId, setRowId] = useState("");

  useEffect(() => {
    function start() {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: [
            "https://sheets.googleapis.com/$discovery/rest?version=v4"
          ],
          scope: "https://www.googleapis.com/auth/spreadsheets.readonly"
        })
        .then((result: any) => {
          console.log("result!", result);
          gapi.auth2.getAuthInstance().isSignedIn.listen(setIsSignedIn);
          setIsSignedIn(gapi.auth2.getAuthInstance().isSignedIn.get());
        })
        .catch((error: any) => {
          console.error("Error al iniciar la API de Google", error);
        });
    }

    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    const code = searchParams.get("code");
    const sheet = searchParams.get("sheet");
    if (isSignedIn && code && sheet) {
      fetchRowData({ code, sheet });
    }
  }, [isSignedIn, searchParams]);

  const handleSignIn = () => {
    gapi.auth2.getAuthInstance().signIn();
  };

  const handleSignOut = () => {
    gapi.auth2.getAuthInstance().signOut();
  };

  const fetchRowData = async (
    {
      code,
      sheet
    }: {
      code?: any;
      sheet?: any;
    } = { code: null, sheet: null }
  ) => {
    if (!rowId && !code) {
      alert("Por favor, ingresa un ID de fila");
      return;
    }

    try {
      const id = code || rowId;
      const params = {
        spreadsheetId: SPREADSHEET_ID,
        range: `${sheet ? sheet : SHEET_NAME}!A:Z`
        /* range: `${SHEET_NAME}!A${rowId}:Z${rowId}` */
      };
      console.log("params", params);
      const response = await gapi.client.sheets.spreadsheets.values.get(params);
      const rows = response.result.values;
      if (!rows) {
        alert("No se encontraron datos!");
        return;
      }
      const value = rows.find((row: any) => row[2] === id);

      if (!value) {
        alert("No se encontró el item!");
        return;
      }
      setData(value);
    } catch (error) {
      console.error("Error al leer los datos de la hoja de cálculo", error);
    }
  };

  return (
    <>
      <Grid container style={{ minHeight: "80vh" }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h1>Inventario CDIR</h1>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {!isSignedIn ? (
            <button onClick={handleSignIn}>Iniciar Sesión</button>
          ) : (
            <button onClick={handleSignOut} style={{ marginBottom: "2rem" }}>
              Cerrar Sesión
            </button>
          )}

          {isSignedIn && (
            <div>
              <input
                type="text"
                placeholder="Ingrese Código"
                value={rowId}
                onChange={(e) => setRowId(e.target.value)}
              />
              <button onClick={() => fetchRowData()}>Buscar Ítem</button>
            </div>
          )}

          {data && (
            <div>
              <h2>Datos del Item:</h2>
              {/*    <pre>{JSON.stringify(data, null, 2)}</pre> */}
              <div className="table-container">
                <table style={{ width: "100%" }}>
                  <tbody>
                    {data &&
                      data.map((cell: any, index: any) => {
                        console.log("cell", cell);
                        return (
                          <tr
                            key={index}
                            style={{
                              display: COLUMNS[index] ? "block" : "none"
                            }}
                          >
                            {/* <td data-label={COLUMNS[index]}>
                              {COLUMNS[index]}
                            </td> */}
                            <td data-label={COLUMNS[index]}>
                              {cell.toString()}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
