import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { FirestoreProvider, useFirebaseApp } from "reactfire";
import { getFirestore } from "firebase/firestore";
import { Routes, Route, Outlet, Link } from "react-router-dom";
//pages
import Inicio from "./pages/inicio";
import LaCorporacion from "./pages/la_corporacion";
import NosotrasNosotros from "./pages/nosotras_nosotros";
import Contacto from "./pages/contacto";
import Proyectos from "./pages/proyectos";
import Noticias from "./pages/noticias";
import NoticiasDetalle from "./pages/noticias_detalle";
import ProyectosDetalle from "./pages/proyectos_detalle";
import ProyectosDetalleEspecifico from "./pages/proyectos_detalle_especifico";
import SocialFeed from "./pages/social_feed";
import Ticket from "./pages/tickets";
import Inventory from "./pages/inventory";

//Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Box } from "@mui/material";

import "./App.css";
import TerminosCondiciones from "./pages/terminos_condiciones";
// Theme
const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(",")
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Inicio />
  },
  {
    path: "la_corporacion",
    element: <LaCorporacion />
  },
  {
    path: "nosotras_nosotros",
    element: <NosotrasNosotros />
  },
  {
    path: "contacto",
    element: <Contacto />
  },
  {
    path: "proyectos",
    element: <Proyectos />
  },
  {
    path: "noticias",
    element: <Noticias />
  },
  {
    path: "terminos_condiciones",
    element: <TerminosCondiciones />
  },
  {
    path: "noticias/:newsId",
    element: <NoticiasDetalle />
  },
  {
    path: "proyectos/:projects",
    element: <ProyectosDetalle />
  },
  {
    path: "/proyectos/:projectId/detalle",
    element: <ProyectosDetalleEspecifico />
  },
  {
    path: "/ticket",
    element: <Ticket />
  }
  /* {
    path: "*",
    element: <Inicio />
  } */
]);

function App() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const shouldDelayHeader = window.location.pathname === "/";

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <div className="App">
        <Box
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            marginTop: shouldDelayHeader && isLoading ? "0" : "100px"
          }}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {shouldDelayHeader && isLoading ? null : <Header />}
            {/*  <RouterProvider router={router} /> */}
            <Routes>
              <Route path="/" element={<Inicio />} />
              <Route path="/la_corporacion" element={<LaCorporacion />} />
              <Route path="/nosotras_nosotros" element={<NosotrasNosotros />} />
              <Route path="/contacto" element={<Contacto />} />
              <Route path="/proyectos" element={<Proyectos />} />
              <Route path="/noticias/:newsId" element={<NoticiasDetalle />} />
              <Route path="/noticias" element={<Noticias />} />
              <Route
                path="/terminos_condiciones"
                element={<TerminosCondiciones />}
              />
              <Route
                path="/proyectos/:projectId/detalle"
                element={<ProyectosDetalleEspecifico />}
              />
              <Route
                path="/proyectos/:projects"
                element={<ProyectosDetalle />}
              />
              <Route path="/social-feed" element={<SocialFeed />} />
              <Route path="/social-feed/:status" element={<SocialFeed />} />
              <Route path="/ticket" element={<Ticket />} />
              <Route path="/inventario" element={<Inventory />} />
              <Route path="*" element={<Inicio />} />
            </Routes>

            <Outlet />
            <Footer />
          </ThemeProvider>
        </Box>
      </div>
    </FirestoreProvider>
  );
}

export default App;
