import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ProjectsAll, projectsDataDetail } from "../../providers/projects";
export default function Projects() {
  const sliderRef = useRef<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [project, setProject] = useState<ProjectsAll[]>([]);
  const [projectid, setProjectid] = useState<ProjectsAll | "">();

  useEffect(() => {
    async function fetchData() {
      const newsData = await projectsDataDetail();

      setProject(newsData);
    }
    fetchData();
  }, []);

  const slidesToShow = window.innerWidth < 600 ? 1 : 3;

  const handleSlideChange = (newSlide: number) => {
    setCurrentSlide(newSlide);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next);
    },
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          paddingBottom: "244px",
          paddingTop: "200px",
        }}
      >
        <Grid
          lg={9}
          md={10.5}
          xs={10.5}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "left",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "700",
              fontSize: "47px",
              display: { xs: "none", md: "none", lg: "flex" },
            }}
          >
            Proyectos
          </Typography>
          <Typography
            variant="h1"
            sx={{
              textAlign: "left",
              fontWeight: "700",
              fontSize: "22px",
              display: { xs: "flex", md: "flex", lg: "none" },
            }}
          >
            Proyectos
          </Typography>
        </Grid>
        <Box
          sx={{
            maxWidth: "100%",
            paddingLeft: "-10px",
            paddingRight: "10px",
            overflow: "hidden",
          }}
        >
          <Grid
            md={9.2}
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "left",
            }}
          >
            <Box sx={{ display: { xs: "none", md: "none", lg: "block" } }}>
              <Slider {...settings} ref={sliderRef}>
                {project.map((data, index) => (
                  <div key={index}>
                    <Link
                      href={`/proyectos/${data.urlKey}/detalle`}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        sx={{
                          maxWidth: 604,
                          border: "none !important",
                          boxShadow: "0 0 transparent",
                          borderRadius: "0",
                        }}
                      >
                        <CardActionArea component="div" disabled disableRipple>
                          <CardMedia
                            component="img"
                            height="403"
                            image={data.previewImage?.src || ""}
                            alt={data.previewImage?.title || ""}
                            sx={{ margin: "20px" }}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{ textAlign: "left" }}
                            >
                              {data.title || ""}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </div>
                ))}
              </Slider>
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "block", lg: "none" },
                maxWidth: "100%",
                paddingLeft: "-10px",
                paddingRight: "10px",
                overflow: "hidden",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  scrollBehavior: "smooth",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                {project.map((data, index) => (
                  <div
                    key={index}
                    style={{
                      minWidth: `calc(85% / ${slidesToShow})`,
                      padding: "0 8px",
                      transition: "transform 0.3s",
                      transform: `translateX(-${
                        currentSlide * (100 / slidesToShow)
                      }%)`,
                    }}
                  >
                    <Link
                      href={`/proyectos/${data.urlKey}/detalle`}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{
                          maxWidth: 604,
                          border: "none",
                          boxShadow: "0 0 transparent",
                          borderRadius: 0,
                          minHeight: "403px",
                        }}
                      >
                        <CardActionArea component="div" disableRipple>
                          <CardMedia
                            component="img"
                            height="403"
                            image={data.previewImage?.src || ""}
                            alt={data.previewImage?.title || ""}
                            style={{ margin: "20px" }}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{
                                textAlign: "left",
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {data.title || ""}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </div>
                ))}
              </Box>
            </Box>

            {/* Arrows Desktop */}
            <Box
              sx={{
                top: "100%",
                marginLeft: "96.1%",
                display: { xs: "none", md: "none", lg: "flex" },
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={goToPrevSlide}
              >
                <ArrowBackIcon />
              </div>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={goToNextSlide}
              >
                <ArrowForwardIcon />
              </div>
            </Box>
          </Grid>
        </Box>
        <Box
          sx={{
            display: { xs: "display", md: "display", lg: "none" },
            justifyContent: "right",
            paddingLeft: "200px",
            marginBottom: "-100px",
          }}
        >
          <Button
            href="/proyectos"
            variant="outlined"
            color="inherit"
            sx={{
              border: "none",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              textTransform: "none",
                marginLeft: "-30px"
            }}
          >
            Ver más proyectos <ArrowForwardIcon sx={{ scale:"90%" }} />
          </Button>
        </Box>
      </Grid>
    </>
  );
}
