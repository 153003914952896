import axios from "axios";

export interface Image {
  src: string;
  title: string;
  _id: string;
}

export interface Cover {
  src: string;
}

export interface Social {
  events: [];
  tags: [];
  _id: string;
  newsId: string;
  description: string;
  mediaType: string;
  mediaUrl: string;
  multimedia: Image[];
  publicationDate: string;
  account: string;
  permalink: string;
  origin: string;
  raw: [];
  cover: Cover;
  compoundKey: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export async function getSocialData(): Promise<Social[]> {
  try {
    const { data } = await axios.get(
      "https://services.smartrancagua.com/news/list",
      {
        params: {
          query: {
            account: "desarrollo_innovacion",
          },
        },
      },
    );

    const { rows } = data

    rows.sort((a : Social, b : Social) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateB - dateA;
    });

    return rows;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

export async function getSocialDataFilter(): Promise<Social[]> {
  try {
    const { data } = await axios.get(
        "https://services.smartrancagua.com/news/list",
        {
          params: {
            query: {
              $or:[
                {tags: "#historiaentusmanos"},
                {tags: "#alegóricosrancagua"},
                {tags: "#alegóricosrancagua2023"},
                {tags: "#identidadrancagua"},
                {tags: "#HistoriaEnTusManos"},
              ]
            },
          },
        },
    );
    const { rows } = data

    rows.sort((a : Social, b : Social) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateB - dateA;
    });

    return rows;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}
