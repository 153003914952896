import React, { useState, useEffect } from "react";
//components
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Pagination,
  Link
} from "@mui/material";
import Loading from "../../components/Loading";
//providers
import { getNewsData, News } from "../../providers/news";
import { initGA, logPageView } from "../../helpers/analytics";
export default function Noticias() {
  const itemsPerPage = 6;
  const [activePage, setActivePage] = useState(1);
  const [news, setNews] = useState<News[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function preloadImages(imageUrls: string[]) {
    const promises = imageUrls.map((url) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve();
        img.onerror = reject;
      });
    });
    await Promise.all(promises);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const newsData = await getNewsData();
        setNews(newsData);

        const imageUrls = newsData.map(
          (news) => news.previewImage?.src || news.images[0].src
        );
        await preloadImages(imageUrls);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setActivePage(page);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("page", String(page));
    window.history.pushState({}, "", newUrl.toString());
  };

  const formatDate = (dateString: string) => {
    const parts = dateString.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  const startIndex = (activePage - 1) * itemsPerPage;
  const paginatedNews = news.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <Grid
            lg={8.85}
            container
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              paddingBottom: "21px",
              paddingTop: "109.41px",
              textAlign: "left",
              display: { xs: "none", md: "none", lg: "flex" }
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: "47px",
                fontWeight: "700",
                display: { xs: "none", md: "none", lg: "flex" }
              }}
            >
              Noticias
            </Typography>
          </Grid>

          {/* Desktop */}
          <Grid
            container
            lg={9.1}
            spacing={3}
            sx={{
              display: { xs: "none", md: "none", lg: "flex" },
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            {paginatedNews.map((news, key) => (
              <Grid key={key} item lg={4}>
                <Link
                  href={`/noticias/${news.urlKey}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    sx={{
                      "&:hover": {
                        color: "#b73c8e",
                        textDecoration: "underline"
                      },
                      "&:active": {
                        color: "#b73c8e",
                        textDecoration: "underline"
                      },
                      border: "none !important",
                      boxShadow: "0 0 transparent !important",
                      borderRadius: "0",
                      textAlign: "left",
                      maxWidth: 415
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="367px"
                        image={news.previewImage?.src || news.images[0].src}
                        alt={news.previewImage?.title || news.images[0].title}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h1"
                          component="div"
                          sx={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            marginLeft: "-15px"
                          }}
                        >
                          {news.title}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginLeft: "-15px" }}
                        >
                          {news.publicationDate?.substring(0, 10) &&
                            formatDate(news.publicationDate.substring(0, 10))}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>

          {/* Mobile */}
          <Grid
            container
            spacing={3}
            sx={{ display: { xs: "flex", md: "flex", lg: "none" } }}
          >
            <Grid
              item
              xs={10.5}
              md={10.5}
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "left"
              }}
            >
              <Typography sx={{ fontSize: "23px", fontWeight: "700" }}>
                Noticias
              </Typography>
            </Grid>
            {paginatedNews.map((news, key) => (
              <Grid
                key={key}
                item
                xs={10.5}
                md={10.5}
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "left"
                }}
              >
                <Link
                  href={`/noticias/${news.urlKey}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    sx={{
                      "&:hover": {
                        color: "#b73c8e",
                        textDecoration: "underline"
                      },
                      "&:active": {
                        color: "#b73c8e",
                        textDecoration: "underline"
                      },
                      border: "none",
                      boxShadow: "none",
                      borderRadius: "0",
                      textAlign: "left"
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        width="100%"
                        height="258px"
                        image={news.previewImage?.src || news.images[0].src}
                        alt={news.previewImage?.title || news.images[0].title}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h1"
                          component="div"
                          sx={{
                            fontWeight: "700",
                            fontSize: "18px",
                            marginLeft: "-15px"
                          }}
                        >
                          {news.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            marginLeft: "-15px"
                          }}
                        >
                          {news.publicationDate?.substring(0, 10) &&
                            formatDate(news.publicationDate.substring(0, 10))}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
          {/* Pagination */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "83px",
              paddingBottom: "172px"
            }}
          >
            <Pagination
              count={Math.ceil(news.length / itemsPerPage)}
              page={activePage}
              onChange={handlePaginationChange}
              shape="rounded"
              sx={{ paddingLeft: { lg: "55%", md: "0", xs: "0" } }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
