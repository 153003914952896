export const getCategories = () => [
  {
    title: "Nuestros Orígenes",
    description:
      'Se entenderá como "Nuestros Orígenes" a aquel proceso histórico que da cuenta de nuestros aborígenes en el territorio ¿Quiénes habitaban nuestro territorio?¿Cuáles fueron sus actividades principales?¿Cómo aportaron en nuestra historia?¿Cómo fue su encuentro con los primeros conquistadores foráneos del territorio? Costumbres de los primeros habitantes de Rancagua y otra información que se desee relevar.',
  },
  {
    title: "Personajes, Mitos y Leyendas",
    description:
      'La historia de Rancagua está -al igual que otros lugares del país- conformada por la innumerable existencia de personajes, mitos y leyendas que han trascendido a lo largo de estos años. Un ejemplo de ello es "Tito Lastarria", que ha sido parte de la Leyenda Urbana de nuestra ciudad, el cual ha traspasado fronteras geográficas y temporales. Demás mencionar a Óscar Castro, quien es uno de nuestros emblemas locales y nacionales.',
  },
  {
    title: "Hitos para un País",
    description:
      "Rancagua ha sido protagonista de grandes episodios de la historia nacional, los cuales necesitamos relevar y mantener vivos hoy y siempre. El más evidente es, sin duda alguna, la Batalla de Rancagua; la cual marcó un proceso en la historia de nuestro país. Del mismo modo, podríamos mencionar la Tragedia del Humo, que dejó un precedente en la historia de la minería nacional e internacional; la Nacionalización del Cobre, entre muchas otras.",
  },
  {
    title: "Historia de las Mujeres de Rancagua",
    description:
      "Bien sabemos que las mujeres han sido invisibilizadas en la historia nacional y también en el ámbito local. Y, sin duda alguna, su participación en la historia pasada y reciente ha estado muy presente. Sabemos que es un gran desafío escudriñar y visibilizar a las mujeres de nuestra comuna que han sido fundamentales en la construcción de la identidad rancagüina. Cuando hablamos de historia de las mujeres, podemos pensar en: la primera mujer diputada del territorio, la primera editora de prensa del país (que se asentó en la comuna de Rancagua por largo tiempo), la primera mujer minera que logró entrar a una mina, artistas,dirigentas sociales, docentes, nuestras antecesoras, las dirigentas de la Población Las Viudas, entre muchas más. Lo importante, en este punto, es dar un paso fundamental en la historia local desde la mirada de las mujeres.",
  },
  {
    title: "Patrimonio",
    description:
      "Rancagua cuenta con una riqueza patrimonial que está presente en cada rincón de la ciudad y con la que convivimos a diario. Sin embargo, sabemos que esta fuente de historia no siempre está a disposición del conocimiento de todos y todas. Por lo tanto, esta es una oportunidad para visibilizarlas y reconocer el patrimonio rancagüino y compartirlo con toda la comunidad que sea partícipe de este evento. Algunos de los espacios patrimoniales de Rancagua (y que fueron relevados en el último Día de los Patrimonios 2023) son, la Casa de la Cultura, el Castillo Butrón, la Iglesia de la Merced, el Pasaje Trénova, entre otros.",
  },
  {
    title: "Costumbres e Identidad",
    description:
      "Cada espacio nacional se caracteriza por sus propias costumbres, actividades, comidas y fiestas tradicionales que dan vida a la identidad local. Rancagua, naturalmente, no está exenta de esto y, en este marco de historia y recuperación de nuestra memoria queremos promoverlas y difundirlas. La invitación es a compartir e investigar con nuestros antepasados o historiadores locales que nos permitan dar con un tema digno de relevar.",
  },
];
