export const getSponsors = () => [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Fpatrocinadores%2FWebp%2FDiario-El-Rancagu%CC%88ino-cuadrado.webp?alt=media&token=c873138d-4aab-42d4-bca0-5ccc4ab239e5",
    alt: "Diario el Rancaguino",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Fpatrocinadores%2FWebp%2FLogo_MallPatioRancagua-01-cuadrado.webp?alt=media&token=e4d6c442-eeaa-4ab6-ad1d-af20ebbb0c03",
    alt: "Mall Patio Rancagua",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Fpatrocinadores%2FWebp%2FPlaza-Ame%CC%81rica-logo_cuadrado.webp?alt=media&token=a5f014cf-6205-40b4-8e39-9b7949c951dc",
    alt: "Plaza America",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Fpatrocinadores%2FWebp%2Fla-boheme_logo-cuadrado.webp?alt=media&token=d2ce2934-9666-4e01-8e7f-7439c01aff33",
    alt: "La Boheme",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Fpatrocinadores%2FWebp%2Flogo-oficial-radio-bienvenida-cuadrado.webp?alt=media&token=311bd7d3-a0cd-49e4-b167-d33076a25e19",
    alt: "Radio Bienvenida",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Fpatrocinadores%2FWebp%2Flogo-parque-safari-cuadrado.webp?alt=media&token=f3d441dd-bce7-4168-91cc-2eaffffb8d76",
    alt: "Parque Safari",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Fpatrocinadores%2FWebp%2Fruta-del-maipo_cuadrado.webp?alt=media&token=c6db1839-2cb5-4d2f-a34a-acdeb5564912",
    alt: "Ruta del maipo",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Fpatrocinadores%2FWebp%2Flogo_corporacio%CC%81n-cuadrado.webp?alt=media&token=f16408ed-b848-4990-8c33-109d36c8bde9",
    alt: "Corporacion de Desarrollo e Innovacion",
  },
];

export const getSchools = () => [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2Fliceo-industrial.webp?alt=media&token=bba13ec1-19ed-4f4d-bfff-40460011073f",
    alt: "Liceo Bicentenario Industrial",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2FLiceo-Diego-Portales-.webp?alt=media&token=201d5635-66fc-48d9-be8d-2a8ac5f96236",
    alt: "Liceo Diego Portales",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2FLiceo-JAR-.webp?alt=media&token=a0de4317-7310-4ca7-9221-db31f80fb92e",
    alt: "Liceo JAR",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2FLiceo-JVLS-.webp?alt=media&token=7008cf35-dd7b-4b5e-8611-9e916516abcf",
    alt: "Liceo JVLS",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2FLiceo-Oscar-Castro-.webp?alt=media&token=c1cfa80f-9bda-49a3-9d01-441466546636",
    alt: "Liceo Oscar Castro",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2FLiceo-SB-.webp?alt=media&token=559fd69f-946e-47ec-a77b-90fcabfbd783",
    alt: "Liceo SB",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2FLiceo-Tello-.webp?alt=media&token=a5877f4b-52f1-4d5a-8d26-dd600b8d2764",
    alt: "Liceo Tello",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2FLiceo-Te%CC%81cnico-Santa-Cruz-de-Triana-.webp?alt=media&token=71b4b60e-9fce-45a8-bc90-5797a37a2834",
    alt: "Liceo Tecnico Santa Cruz de Triana",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2FLiceo-de-Nin%CC%83as-.webp?alt=media&token=6f905479-4b60-47fa-b057-be214b9ba289",
    alt: "Liceo de Niñas",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2FLogos%2Festablecimientos%2FWebp%2FInstituto-Tecnolo%CC%81gico-Minero-.webp?alt=media&token=5222f357-5a23-460e-89e6-cb90a0d5fd8e",
    alt: "Instituto Tecnologico Minero",
  },
];
