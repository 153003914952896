import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirestoreDocData,
  useFirestore,
  useFirebaseApp,
} from "reactfire";

//Components
import Loading from "components/Loading";
import ProjectDescriptionSanitize from "components/SanitizeHTML";
import Grid from "@mui/material/Grid";
import { Typography, Box, ImageList, ImageListItem, Link } from "@mui/material";

//Providers
import {
  getProjectsData,
  ProjectsNew,
  ProjectsAll,
  projectsCategoryData,
} from "../../providers/projects";
import { initGA, logPageView } from "../../helpers/analytics";

//Styles
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import Title from "../../components/Title";

export default function ProyectosDetalle() {
  const { projects } = useParams();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const [projectData, setProjectData] = useState<ProjectsNew | null>(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const fetchCategoryData = async (data: ProjectsNew) => {
    const categoryPromises = data.projects.map(
      async (projectItem: ProjectsAll) => {
        const categoryData = await projectsCategoryData(projectItem._id);
        return {
          ...projectItem,
          categoryData: categoryData,
        };
      },
    );

    const updatedProjectsWithCategory = await Promise.all(categoryPromises);
    setProjectData({
      ...data,
      projects: updatedProjectsWithCategory,
    });
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getProjectsData(projects);
      setProjectData(data);
      if (data) {
        await fetchCategoryData(data);
      }
    }
    fetchData();
  }, [projects]);

  useEffect(() => {
    if (projectData && projectData.coverDesktop && projectData.coverMobile) {
      const coverDesktopImage = new Image();
      coverDesktopImage.src = projectData.coverDesktop.src;
      coverDesktopImage.onload = () => {
        const coverMobileImage = new Image();
        coverMobileImage.src = projectData.coverMobile.src;
        coverMobileImage.onload = () => {
          setImagesLoaded(true);
        };
      };
    } else {
      setImagesLoaded(true);
    }
  }, [projectData]);
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  if (!projectData || !imagesLoaded) {
    return <Loading />;
  }

  return (
    <>
      {projectData.coverDesktop && (
        <Box sx={{ display: { xs: "none", md: "none", lg: "flex" } }}>
          <img
            src={projectData.coverDesktop.src}
            alt={projectData.coverDesktop.title}
            width="100%"
          />
        </Box>
      )}
      {projectData.coverMobile && (
        <Box sx={{ display: { xs: "flex", md: "flex", lg: "none" } }}>
          <img
            src={projectData.coverMobile.src}
            alt={projectData.coverMobile.title}
            width="100%"
          />
        </Box>
      )}
      <Grid
        item
        lg={9}
        md={10.5}
        xs={10.5}
        container
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "left",
        }}
      >
        <Grid item lg={12}>
          <ProjectDescriptionSanitize description={projectData.description} />
        </Grid>
        <Grid item lg={12} sx={{ paddingTop: "90px", paddingBottom: "191px" }}>
          <div>
            <ImageList cols={isMobile ? 1 : 2}>
              {projectData.projects.map((item, index) => (
                <div key={index}>
                  <Link
                    href={`/proyectos/${item.urlKey}/detalle`}
                    style={{
                      textTransform: "none",
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ImageListItem>
                      {item.previewImage && item.previewImage.src && (
                        <img
                          src={`${item.previewImage.src}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${item.previewImage.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.previewImage.title}
                          style={{
                            height: isMobile ? "302px" : "403px",
                            width: isMobile ? "364px" : "664px",
                          }}
                        />
                      )}
                    </ImageListItem>
                    {item.title && (
                      <Box
                        sx={{
                          paddingTop: "5%",
                          paddingBottom: "40px",
                          paddingLeft: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "23px", md: "23px", lg: "25px" },
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    )}
                  </Link>
                </div>
              ))}
            </ImageList>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
