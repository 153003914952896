import axios from "axios";

export interface ProjectsNew {
  urlKey: string;
  title: string;
  categoryImage: Image;
  coverDesktop: Image;
  coverMobile: Image;
  description: string;
  projects: ProjectsAll[];
  _id: string;
}

export interface ProjectsAll {
  _id: string;
  title: string;
  subTitle: string;
  description: string;
  banner: Image;
  urlKey: string;
  publicationDate: string;
  gallery: Image[];
  customLanding: boolean;
  video: string;
  previewImage: Image;
  externalUrl: External[];
}

export interface Image {
  src: string;
  title: string;
}
export interface External {
  id: string;
  title: string;
  url: string;
  image: Image;
}
export interface ProjectItem {
  src: string;
  alt: string;
  title: string;
  description: string;
}

export async function projectsData(): Promise<ProjectsNew[]> {
  try {
    const { data } = await axios.get(
      "https://services.smartrancagua.com/cdir/projectsCategories"
    );
    const { rows } = data;
    return rows;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function projectsDataDetail(): Promise<ProjectsAll[]> {
  try {
    const { data } = await axios.get(
      `https://services.smartrancagua.com/cdir/projects?active=true`
    );
    const { rows } = data;
    return rows;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function projectsCategoryData(
  id: string
): Promise<ProjectsNew | ""> {
  try {
    const { data } = await axios.get(
      `https://services.smartrancagua.com/cdir/projectsCategories/${id}`
    );
    const { rows } = data;
    return rows;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export const getProjectsData = async (projects: string | undefined = "") => {
  const empty = {
    urlKey: "",
    title: "",
    categoryImage: { src: "", title: "" },
    coverDesktop: { src: "", title: "" },
    coverMobile: { src: "", title: "" },
    description: "",
    projects: [],
    _id: ""
  };

  try {
    const projectsDataArray = await projectsData();
    const projectData = projectsDataArray.find(
      ({ urlKey }) => urlKey === projects
    );

    if (projectData) {
      const categoryData = await projectsCategoryData(projectData._id);
      const projectPromises = projectData.projects.map(async (item: any) => {
        const { data: projectItemData } = await axios.get(
          `https://services.smartrancagua.com/cdir/projects/${item}`
        );
        return projectItemData;
      });

      const projectImages = await Promise.all(projectPromises);
      projectData.projects = projectImages;

      return projectData;
    }

    return empty;
  } catch (error) {
    console.log("error", error);
    return empty;
  }
};

export const getProjectDataDetail = async (
  projectsDetail: string | undefined = ""
) => {
  try {
    const empty = {
      urlKey: "",
      title: "",
      description: "",
      _id: "",
      subTitle: "",
      banner: { src: "", title: "" },
      publicationDate: "",
      gallery: [],
      video: "",
      customLanding: false,
      previewImage: { src: "", title: "" },
      externalUrl: []
    };

    /* const projectsDataArray = await projectsDataDetail(); */
    /*   const projectDataDetail = projectsDataArray.find(
      ({ urlKey }) => urlKey === projectsDetail
    ); */

    const { data } = await axios.post(
      "https://services.smartrancagua.com/cdir/projects/findByQuery",
      {
        query: {
          urlKey: projectsDetail
        }
      }
    );
    if (data) {
      return data;
    }

    return empty;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
