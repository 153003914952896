export interface Participant {
  id: string;
  urlKey: string;
  name: string;
  category: string;
  number: number;
  coverImage?: string;
  college: string;
  numberVotes?: number;
  images: string[];
}

const PARTICIPANT_LIST: Participant[] = [
  {
    id: "1",
    urlKey: "teniente-1945",
    college: "Liceo Bicentenario Industrial Presidente Pedro Aguirre Cerda",
    number: 7,
    name: "Teniente 1945",
    category: "Hitos para un País",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoIndustrial%2Fpedro-aguirre-cerda_1.png?alt=media&token=75a27a0b-9b51-43f0-a29a-a0cbd2f4bffe",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoIndustrial%2Fpedro-aguirre-cerda_1.png?alt=media&token=75a27a0b-9b51-43f0-a29a-a0cbd2f4bffe",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoIndustrial%2Fpedro-aguirre-cerda_2.png?alt=media&token=5e80f773-d6ad-452c-84b3-21625ddbcf44",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoIndustrial%2Fpedro-aguirre-cerda_3.png?alt=media&token=00ec9a62-512d-412a-9241-00783ef2e70e",
    ],
    numberVotes: 0,
  },
  {
    id: "2",
    urlKey: "desastre-de-rancagua",
    college: "Liceo Integrado Libertador Simón Bolívar",
    number: 3,
    name: "Desastre de Rancagua",
    category: "Hitos para un País",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoSB%2Fsimon_bolivar_1.png?alt=media&token=61803129-7e0c-4da0-ac35-677039213cbd",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoSB%2Fsimon_bolivar_1.png?alt=media&token=61803129-7e0c-4da0-ac35-677039213cbd",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoSB%2Fsimon_bolivar_2.png?alt=media&token=09df44b5-0c68-45fc-a0f1-565785f03493",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoSB%2Fsimon_bolivar_3.png?alt=media&token=cf9861c4-086f-4dde-b818-42975e0b490f",
    ],
    numberVotes: 0,
  },
  {
    id: "3",
    urlKey: "batalla-de-rancagua",
    college: "Liceo Francisco Tello González",
    number: 6,
    name: "Batalla de Rancagua 2023",
    category: "Patrimonio",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoTello%2Ffrancisco-tello_1.png?alt=media&token=e19d3f09-c9a3-4c2e-9489-76c40f1055fb",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoTello%2Ffrancisco-tello_1.png?alt=media&token=e19d3f09-c9a3-4c2e-9489-76c40f1055fb",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoTello%2Ffrancisco-tello_2.png?alt=media&token=d60cedff-e302-4c9a-ae8d-93d925bf24ae",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoTello%2Ffrancisco-tello_3.png?alt=media&token=a3e02b3d-04f0-4ab5-8282-8930302bcf21",
    ],
    numberVotes: 0,
  },
  {
    id: "4",
    urlKey: "circo-x-circo-el-palote",
    college: "Liceo Óscar Castro",
    number: 4,
    name: 'Circo x Circo "El Palote"',
    category: "Costumbres e Identidad ",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoOscarCastro%2Foscar-castro_1.png?alt=media&token=baecd56c-b7cb-4dad-ad8a-0cd9ffe8ceec",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoOscarCastro%2Foscar-castro_1.png?alt=media&token=baecd56c-b7cb-4dad-ad8a-0cd9ffe8ceec",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoOscarCastro%2Foscar-castro_2.png?alt=media&token=cfa0bda1-d050-41ed-bdfa-11ae3be4b24c",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoOscarCastro%2Foscar-castro_3.png?alt=media&token=124b82d5-4d6f-4797-9784-b35cc32910cd",
    ],
    numberVotes: 0,
  },
  {
    id: "5",
    urlKey: "iglesia-la-merced",
    college: "Liceo Técnico Santa Cruz de Triana",
    number: 8,
    name: "Iglesia La Merced",
    category: "Patrimonio",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoTecnicoSantaCruzdeTriana%2Fsanta-cruz-triana_1.png?alt=media&token=6fd71961-7080-484b-b3d2-5a6d3e786066",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoTecnicoSantaCruzdeTriana%2Fsanta-cruz-triana_1.png?alt=media&token=6fd71961-7080-484b-b3d2-5a6d3e786066",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoTecnicoSantaCruzdeTriana%2Fsanta-cruz-triana_2.png?alt=media&token=f783b4b9-5b2a-41cf-8929-cf4c6698c861",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoTecnicoSantaCruzdeTriana%2Fsanta-cruz-triana_3.png?alt=media&token=6ac3a8d5-c320-46e4-a75f-dfa1335b48a1",
    ],
    numberVotes: 0,
  },
  {
    id: "6",
    urlKey: "el-minero-de-la-sexta",
    college: "Liceo Comercial JAR",
    number: 5,
    name: "El Minero de la Sexta",
    category: "Costumbres e Identidad",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoJar%2Fjorge-alessandri_1.png?alt=media&token=505e23be-4ba4-407b-8026-43e429a3ef45",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoJar%2Fjorge-alessandri_1.png?alt=media&token=505e23be-4ba4-407b-8026-43e429a3ef45",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoJar%2Fjorge-alessandri_2.png?alt=media&token=da5ec9a5-44a2-433a-93d7-1b8abd75f5da",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoJar%2Fjorge-alessandri_3.png?alt=media&token=716ae558-d6d9-47f5-ac5d-b5e2c84e074c",
    ],
    numberVotes: 0,
  },
  {
    id: "7",
    urlKey: "don-tito",
    college: "Liceo José Victorino Lastarria",
    number: 9,
    name: "Don Tito",
    category: "Personajes, Mitos y Leyendas",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoJVLS%2Fvictorino-lastarria_1.png?alt=media&token=515c74bb-53a5-4c76-9cde-a7dd4c7ad261",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoJVLS%2Fvictorino-lastarria_1.png?alt=media&token=515c74bb-53a5-4c76-9cde-a7dd4c7ad261",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoJVLS%2Fvictorino-lastarria_2.png?alt=media&token=201f7cb9-6987-4c03-9909-0138903c0fa3",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoJVLS%2Fvictorino-lastarria_3.png?alt=media&token=dd7ca0c0-e60e-4374-83b8-1c203ef462db",
    ],
    numberVotes: 0,
  },
  {
    id: "8",
    urlKey: "el-viejo-del-abrigo",
    college: "Instituto Tecnológico Minero Bernardo O'Higgins",
    number: 1,
    name: "El Viejo del Abrigo",
    category: "Personajes, Mitos y Leyendas",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FInstitutoTecnologicoMinero%2Fitembo_1.png?alt=media&token=5e1d11ca-428f-4968-b190-6bb35487e6b5",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FInstitutoTecnologicoMinero%2Fitembo_1.png?alt=media&token=5e1d11ca-428f-4968-b190-6bb35487e6b5",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FInstitutoTecnologicoMinero%2Fitembo_2.png?alt=media&token=ee7363cd-e80c-4b50-9300-44bbb9a751ac",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FInstitutoTecnologicoMinero%2Fitembo_3.png?alt=media&token=7721b630-bac4-462d-9584-9565eb81844f",
    ],
    numberVotes: 0,
  },
  {
    id: "9",
    urlKey: "la-metamorfosis",
    college: "Liceo de Niñas",
    number: 2,
    name: "La Metamorfosis de la Mujer",
    category: "Historia de las Mujeres de Rancagua",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceodeNi%C3%B1as%2Fliceo-de-nin%E2%95%A0%C3%A2as_1.png?alt=media&token=1c083430-f178-45ad-b753-6908f393921b",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceodeNi%C3%B1as%2Fliceo-de-nin%E2%95%A0%C3%A2as_1.png?alt=media&token=1c083430-f178-45ad-b753-6908f393921b",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceodeNi%C3%B1as%2Fliceo-de-nin%E2%95%A0%C3%A2as_2.png?alt=media&token=7ba45c35-1808-432c-9f3b-3a40e4b84458",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceodeNi%C3%B1as%2Fliceo-de-nin%E2%95%A0%C3%A2as_4.png?alt=media&token=1f6467d4-fbb6-4ec6-9958-46a4d896ba68",
    ],
    numberVotes: 0,
  },
  {
    id: "10",
    urlKey: "galope-rancaguino",
    college: "Liceo Comercial Diego Portales",
    number: 10,
    name: "Galope Rancagüino: Huellas de Nuestra Tierra",
    category: "Costumbres e Identidad",
    coverImage: "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoDiegoPortales%2Fdiego-portales_1.png?alt=media&token=a0e2e7a2-4853-4b93-93d4-de89b52c5e2c",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoDiegoPortales%2Fdiego-portales_1.png?alt=media&token=a0e2e7a2-4853-4b93-93d4-de89b52c5e2c",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoDiegoPortales%2Fdiego-portales_2.png?alt=media&token=948622c3-2c55-46c2-9bc5-a8068f5f1dd1",
      "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/allegoricalCarsImages%2FLiceoDiegoPortales%2Fdiego-portales_3.png?alt=media&token=b6602749-7beb-44b4-999c-2210e01ce077",
    ],
    numberVotes: 0,
  },
];

export function getParticipant(): Promise<Participant[]> {
  try {
    return Promise.resolve(PARTICIPANT_LIST);
  } catch (error) {
    return Promise.resolve([]);
  }
}

export function getParticipantByParams(params: any): Promise<Participant> {
  try {
    return Promise.resolve(
      PARTICIPANT_LIST.filter(
        (participant) => participant.urlKey === params.urlKey
      )[0]
    );
  } catch (error) {
    return Promise.reject(error);
  }
}
