import React, { useEffect } from "react";
//Components
import Carousel from "../../components/Carousel";
import Feed from "../../components/Feed";
import NewsCard from "../../components/NewsCard";
import Projects from "../../components/Projects";
import SocialNews from "../../components/SocialNews";
import Laws from "../../components/Laws";
import DelayedLoadingWrapper from "../../components/AppWrapper";
import { initGA, logPageView } from "../../helpers/analytics";

export default function Inicio() {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  return (
    <div style={{ maxWidth: "100%", overflow: "hidden" }}>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Carousel />
        <DelayedLoadingWrapper>
          <Feed />
          <NewsCard />
          <Projects />
          <SocialNews />
          <Laws />
        </DelayedLoadingWrapper>
      </div>
    </div>
  );
}
