import axios from "axios";

export interface Image {
  src: string;
  title: string;
}

export interface News {
  urlKey: string;
  cover: Image;
  previewImage: Image;
  title: string;
  description: string;
  summary: string;
  publicationDate: string;
  gallery: Image[];
  video: string;
  externalUrl: External[];
  images: Image[];
  createdAt: string;
  _id: string;
}

export interface External {
  id: string;
  title: string;
  url: string;
  image: Image;
}

export async function getNewsData(): Promise<News[]> {
  try {
    const { data } = await axios.get(
      "https://services.smartrancagua.com/cdir/news",
      {
        params: {
          limit: 100
        }
      }
    );
    const { rows } = data;

    const sortedRows = rows.sort(
      (a: News, b: News) =>
        new Date(b.publicationDate).getTime() -
        new Date(a.publicationDate).getTime()
    );

    return sortedRows;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

export const getNewsByUrlKey = async (urlKey: string | undefined = "") => {
  const empty = {
    urlKey: "",
    cover: { src: "", title: "" },
    previewImage: { src: "", title: "" },
    title: "",
    description: "",
    summary: "",
    publicationDate: "",
    gallery: [],
    video: "",
    externalUrl: [],
    images: [],
    createdAt: "",
    _id: ""
  };
  try {
    const newsDataArray = await getNewsData();
    const newsData = newsDataArray.find((news) => news.urlKey === urlKey);
    /* const { data } = await axios.post(
      "https://services.smartrancagua.com/cdir/news/findByQuery",
      {
        query: {
          urlKey: news,
        },
      },
    ); */
    return newsData;
  } catch (error) {
    console.log("error", error);
    return empty;
  }
};
