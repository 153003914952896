import React from "react";
//Components
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Title from "../Title";
import Paragraph from "../Paragraph";

export default function Feed() {
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          display: { xs: "none", md: "none", lg: "block" },
          paddingTop: "4rem",
          textAlign: "left"
        }}
      >
        <Grid
          lg={9}
          sx={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Box sx={{ paddingBottom: "30px" }}>
            <Title>Desarrollo e Innovación</Title>
          </Box>
          <Paragraph>
            Los proyectos impulsados por la Corporación de Desarrollo e
            Innovación están estrechamente alineados con la misión de mejorar la
            calidad de vida de los vecinos y vecinas de la comuna de Rancagua. A
            través de la innovación social, la tecnología, el emprendimiento, el
            fomento, el cuidado del medio ambiente y otros ámbitos, se busca
            avanzar de manera sostenible en la construcción de una mejor ciudad.
            Este avance se logra mediante un esfuerzo colectivo con diversas
            entidades e instituciones públicas y privadas.
            <br /> <br />
            Desde su fundación, CDIR ha desarrollado proyectos sociales que
            facilitan la democratización de espacios y el acceso a productos,
            promoviendo la igualdad de oportunidades y mejorando la calidad de
            vida de la comunidad.
            <br /> <br />
            En esta misma línea, se han desarrollado soluciones tecnológicas
            para simplificar y optimizar los procesos administrativos,
            fomentando la eficiencia y la transparencia en la gestión municipal,
            y proporcionando una experiencia digital de alta calidad para los
            habitantes de Rancagua.
          </Paragraph>
        </Grid>
      </Grid>
      {/* MOBILE*/}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          display: { xs: "block", md: "block", lg: "none" },
          paddingTop: "2rem",
          textAlign: "left"
        }}
      >
        <Grid
          xs={10.5}
          md={10.5}
          sx={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Box sx={{ paddingBottom: "20px" }}>
            <Title>Desarrollo e Innovación</Title>
          </Box>

          <Paragraph>
            Los proyectos impulsados por la Corporación de Desarrollo e
            Innovación están estrechamente alineados con la misión de mejorar la
            calidad de vida de los vecinos y vecinas de la comuna de Rancagua. A
            través de la innovación social, la tecnología, el emprendimiento, el
            fomento, el cuidado del medio ambiente y otros ámbitos, se busca
            avanzar de manera sostenible en la construcción de una mejor ciudad.
            Este avance se logra mediante un esfuerzo colectivo con diversas
            entidades e instituciones públicas y privadas.
            <br /> <br />
            Desde su fundación, CDIR ha desarrollado proyectos sociales que
            facilitan la democratización de espacios y el acceso a productos,
            promoviendo la igualdad de oportunidades y mejorando la calidad de
            vida de la comunidad.
            <br /> <br />
            En esta misma línea, se han desarrollado soluciones tecnológicas
            para simplificar y optimizar los procesos administrativos,
            fomentando la eficiencia y la transparencia en la gestión municipal,
            y proporcionando una experiencia digital de alta calidad para los
            habitantes de Rancagua.
          </Paragraph>
        </Grid>
      </Grid>
    </>
  );
}
