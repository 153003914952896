import styled from "styled-components";

export const Number = styled.span`
  font-weight: bold;
`;

export const Container = styled.div`
  background-color: #131226;
  color: white;
  padding: 0.5rem;
  border-radius: 16px;
  margin-top: 1rem;
`;
