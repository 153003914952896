import React, { useState, useEffect } from "react";
import TransitionLoading from "../../components/TransitionLoading";
import Box from "@mui/material/Box";
import { initGA, logPageView } from "../../helpers/analytics";

type DelayedLoadingWrapperProps = {
  children: React.ReactNode;
};

const DelayedLoadingWrapper: React.FC<DelayedLoadingWrapperProps> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Box sx={{ zIndex: 999 }}>
        <TransitionLoading isLoading={isLoading} />
        {isLoading ? null : children}
      </Box>
    </>
  );
};

export default DelayedLoadingWrapper;
