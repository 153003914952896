import React from "react";
import ReactDOM from "react-dom/client";
import { doc, getFirestore } from "firebase/firestore";
import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirestoreDocData,
  useFirestore,
  useFirebaseApp
} from "reactfire";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const firebaseConfig = {
  apiKey: "AIzaSyDWWn8k5UPK9dI-HxpJXU3kBlb6gAd-Iww",
  authDomain: "cdir-web.firebaseapp.com",
  databaseURL: "https://cdir-web.firebaseio.com",
  projectId: "cdir-web",
  storageBucket: "cdir-web.appspot.com",
  messagingSenderId: "159143436931",
  appId: "1:159143436931:web:4f7231d480cf5ba74e71b6",
  measurementId: "G-XGY8YC5D7W"
};

root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FirebaseAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
