import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Link, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

//const REDIRECT_URL = "http://localhost:3000/social-feed/success";
const REDIRECT_URL = "https://innovarancagua.cl/social-feed/success";
const INSTAGRAM_CLIENT_ID = "1162036781708295";
export default function SocialFeed() {
  const [searchParams] = useSearchParams();
  console.log("searchParams", searchParams.toString());
  /* const [projectid, setProjectid] = useState<ProjectsNew | "">();
  const [isLoading, setIsLoading] = useState(true);

  async function preloadImages(imageUrls: string[]) {
    const promises = imageUrls.map((url) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve();
        img.onerror = reject;
      });
    });
    await Promise.all(promises);
  }

  useEffect(() => {
    async function fetchData() {
      const newsData = await projectsData();
      setProject(newsData);

      // Preload images
      const imageUrls = newsData.map((data) => data.categoryImage.src);
      await preloadImages(imageUrls);

      setIsLoading(false);
    }
    fetchData();
  }, []);
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }); */

  return (
    <>
      <Grid container style={{ minHeight: "80vh" }}>
        <Grid item xs={0} md={2} />
        <Grid item xs={12} md={8} style={{ padding: "8px" }}>
          <h1>Rancagua Digital</h1>
          <h3 style={{ textAlign: "left" }}>
            Sistema de Sincronización de Noticias para Departamentos y
            Corporaciones Municipales
          </h3>
          <p style={{ textAlign: "left", margin: "1rem 0" }}>
            Rancagua Digital es una plataforma que permite al Municipio de
            Rancagua sincronizar sus noticias en un solo lugar, para que puedan
            ser compartidas en la aplicación Movil de forma centralizada.
          </p>
          <div
            className="fb-like"
            data-share="true"
            data-width="450"
            data-show-faces="true"
          ></div>
          <Button
            onClick={() => {
              const url = `https://api.instagram.com/oauth/authorize?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${encodeURIComponent(
                REDIRECT_URL
              )}&scope=${"user_profile,user_media"}&response_type=code&state=${"local"}`;
              console.log("Comenzar", url);
              window.open(url, "_blank");
            }}
            variant="contained"
            sx={{ textTransform: "none" }}
          >
            Comenzar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
