import React from "react";
import DOMPurify from "dompurify";

import Typography from "@mui/material/Typography";

export interface ProjectDescription {
  description: string;
}

export default function ProjectDescriptionSanitize({
  description
}: ProjectDescription) {
  const fullText = DOMPurify.sanitize(description);
  const renderHTML = (html: any) => {
    return { __html: html };
  };

  return (
    <Typography
      sx={{
        fontSize: { xs: "18px", md: "18px", lg: "18px" },
        fontWeight: "400",
        paddingTop: "134px"
      }}
      dangerouslySetInnerHTML={renderHTML(fullText)}
    />
  );
}

export function NewsDescriptionSanitize({ description }: ProjectDescription) {
  const fullText = DOMPurify.sanitize(description);
  const renderHTML = (html: any) => {
    return { __html: html };
  };

  return (
    <Typography
      sx={{
        fontSize: { xs: "18px", md: "18px", lg: "18px" },
        fontWeight: "400",
        paddingTop: "39px"
      }}
      dangerouslySetInnerHTML={renderHTML(fullText)}
    />
  );
}

export function ProjectsDetailsDescriptionSanitize({
  description
}: ProjectDescription) {
  const fullText = DOMPurify.sanitize(description);
  const renderHTML = (html: any) => {
    return { __html: html };
  };

  return (
    <Typography
      sx={{
        fontSize: { xs: "18px", md: "18px", lg: "18px" },
        fontWeight: "400",
        paddingTop: "39px"
      }}
      dangerouslySetInnerHTML={renderHTML(fullText)}
    />
  );
}
