export const getDirectory = () => [
  {
    company: "Constructora CDI Limitada",
    name: "Gonzalo Díaz Soteras"
  },
  {
    company: "Arquitectura y Construcción Limitada",
    name: "René Corvalán Correa"
  },
  {
    company: "Inmobiliaria e Inversiones Nuevo Horizonte Limitada",
    name: "Juan Pablo Morales Orellana"
  },
  {
    company: "Parque Safari Chile S.A.C",
    name: "Iván Sánchez Lobos"
  },
  {
    company: "Inmobiliaria e Inversiones Nogales Spa",
    name: "Roberto Celedón Fernández"
  },
  {
    company: "Asociación Gremial Dueños de Camiones de la VI Región",
    name: "Javier Lazcano Page"
  }
];
