import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { Box, Link } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getCarousel } from "../../providers/carousel";
import { styled } from "@mui/material/styles";
import TransitionLoading from "../../components/TransitionLoading";

const CarouselContainer = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  overflow: "hidden",
  minHeight: "100%"
}));

export default function Carousel() {
  const carousel = getCarousel();
  const sliderRef = useRef<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const preloadImages = async () => {
      try {
        const imageUrls = carousel.map(({ src = "", src2 = "" }) =>
          window.innerWidth >= 768 ? src : src2
        );

        /* const promises = imageUrls.map((url) => {
          return new Promise<void>((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve();
            img.onerror = reject;
          });
        });

        await Promise.all(promises); */
        setImagesLoaded(true);
      } catch (error) {
        setImagesLoaded(false);
        console.log("error", error);
      }
    };

    preloadImages();
  }, [carousel]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "",
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          adaptiveWidth: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next);
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div style={{ zIndex: 999, position: "relative" }}>
      <CarouselContainer>
        <TransitionLoading isLoading={!imagesLoaded} />
        {imagesLoaded && (
          <>
            <Box sx={{ display: { xs: "none", md: "none", lg: "block" } }}>
              <Slider {...settings} ref={sliderRef}>
                {carousel.map(({ src = "", alt = "", url = "" }, key) => (
                  <div key={key}>
                    <Link
                      href={url}
                      target={url.startsWith("https://") ? "_blank" : "_self"}
                    >
                      <img
                        src={src}
                        alt={alt}
                        style={{ width: "100%", minHeight: "100%" }}
                      />
                    </Link>
                  </div>
                ))}
              </Slider>
              <Box
                sx={{
                  position: "absolute",
                  top: "45%",
                  marginLeft: "6.1%",
                  display: { xs: "none", md: "none", lg: "flex" },
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    marginRight: "96.5rem",
                    cursor: "pointer"
                  }}
                  onClick={goToPrevSlide}
                >
                  <ArrowBackIosIcon sx={{ color: "white", fontSize: "50px" }} />
                </div>
                <div
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={goToNextSlide}
                >
                  <ArrowForwardIosIcon
                    sx={{ color: "white", fontSize: "50px" }}
                  />
                </div>
              </Box>
            </Box>
            {/* MOBILE */}
            <Box
              sx={{
                display: { md: "block", lg: "none" },
                maxWidth: "100%",
                minWidth: "100%",
                minHeight: "60vh",
                zIndex: 999
              }}
            >
              <Slider {...settings}>
                {carousel.map(({ src2 = "", alt = "", url = "" }, key) => (
                  <Link
                    key={key}
                    href={url}
                    target={url.startsWith("https://") ? "_blank" : "_self"}
                  >
                    <div>
                      {src2 ? (
                        <img src={src2} alt={alt} style={{ width: "100%" }} />
                      ) : (
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fcarousel%2F4-mobile.webp?alt=media&token=635daccb-94d9-4a64-ae3f-2e9b86de7770"
                          alt="Image"
                          style={{ width: "100%" }}
                        />
                      )}
                    </div>
                  </Link>
                ))}
              </Slider>
            </Box>
          </>
        )}
      </CarouselContainer>
    </div>
  );
}
