import React, { useEffect, useState } from "react";
// Components
import {
  Card,
  CardMedia,
  CardActionArea,
  Typography,
  CardContent,
  Stack,
  Box,
  Link
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "@mui/material/Unstable_Grid2";
// Providers
import { getSocialData, Social } from "../../providers/socialnews";

export default function SocialNews() {
  const [social, setSocial] = useState<Social[]>([]);
  useEffect(() => {
    async function fetchData() {
      const socialData = await getSocialData();
      setSocial(socialData);
    }
    fetchData();
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          centerMode: true,
          centerPadding: "50px"
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          centerMode: true,
          centerPadding: "50px"
        }
      }
    ]
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#b73c8e",
          display: { xs: "none", md: "none", lg: "flex" }
        }}
      >
        <Grid
          sx={{
            paddingBottom: "85px",
            paddingLeft: "235px",
            paddingRight: "235px",
            paddingTop: "69px",
            textAlign: "left"
          }}
        >
          <Box sx={{ display: { xs: "none", md: "none", lg: "flex" } }}>
            <img
              src="/siguenos.png"
              alt="siguenos"
              style={{ paddingBottom: "30px" }}
            />
          </Box>
          <Stack
            direction="row"
            spacing={11}
            sx={{ display: { xs: "none", md: "none", lg: "flex" } }}
          >
            {social.slice(0, 4).map((socialData, key) => (
              <Link
                key={key}
                href={socialData.permalink}
                target="_blank"
                sx={{ textDecoration: "none" }}
              >
                <Card
                  sx={{
                    minHeight: 510,
                    maxWidth: 274,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    borderRadius: "0"
                  }}
                >
                  <CardActionArea>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="/socialnews/logo.png"
                        alt="icon"
                        style={{ paddingBottom: "16px", paddingTop: "15px" }}
                      />
                      <p style={{ marginLeft: "11px" }}>{socialData.account}</p>
                    </div>
                    <CardMedia
                      component="img"
                      height="273.08"
                      image={
                        socialData.mediaType === "VIDEO"
                          ? socialData.cover.src
                          : socialData.mediaUrl
                      }
                      alt={socialData.mediaType}
                    />
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          textAlign: "left"
                        }}
                      >
                        {socialData.description.length > 150
                          ? `${socialData.description.slice(0, 150)}...`
                          : socialData.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            ))}
          </Stack>
        </Grid>
      </Box>
      {/* MOBILE */}
      <Box
        sx={{
          backgroundColor: "#b73c8e",
          display: { xs: "block", md: "block", lg: "none" }
        }}
      >
        <Grid
          xs={10.5}
          md={10.5}
          sx={{ display: { xs: "block", md: "block", lg: "none" } }}
        >
          <Box>
            <img
              src="/siguenos.png"
              alt="siguenos"
              style={{
                paddingBottom: "30px",
                width: "165px",
                paddingTop: "54px",
                marginLeft: "-44%"
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "block", md: "block", lg: "none" },
              maxWidth: "100%",
              paddingBottom: "100px",

              paddingLeft: "-10px",
              paddingRight: "10px",
              overflow: "hidden"
            }}
          >
            <Box
              style={{
                display: "flex",
                overflowX: "scroll",
                scrollBehavior: "smooth",
                WebkitOverflowScrolling: "touch"
              }}
            >
              {social.slice(0, 4).map((socialData, key) => (
                <div key={key} style={{ margin: "0 10px" }}>
                  <Link
                    href={socialData.permalink}
                    target="_blank"
                    sx={{ textDecoration: "none" }}
                  >
                    <Card
                      sx={{
                        minHeight: 532,
                        width: 274,
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: 0,
                        margin: "0 10px"
                      }}
                    >
                      <CardActionArea sx={{ paddingBottom: "15px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src="socialnews/logo.png"
                            alt="icon"
                            style={{
                              paddingBottom: "16px",
                              paddingTop: "15px"
                            }}
                          />
                          <p style={{ marginLeft: "11px" }}>
                            {socialData.account}
                          </p>
                        </div>
                        <CardMedia
                          component="img"
                          image={
                            socialData.mediaType === "VIDEO"
                              ? socialData.cover.src
                              : socialData.mediaUrl
                          }
                          alt={socialData.mediaType}
                          height="273.081px"
                        />
                      </CardActionArea>
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            textAlign: "left"
                          }}
                        >
                          {socialData.description.length > 150
                            ? `${socialData.description.slice(0, 150)}...`
                            : socialData.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </div>
              ))}
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
}
