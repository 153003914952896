import axios from "axios";

export interface Department {
  name: string;
  description?: string;
  level?: number;
  team?: Team[];
}

export interface Team {
  name: string;
  position?: string;
  level?: number;
  contact?: Contact;
}

export interface Contact {
  email?: string;
  phone?: string;
}

export async function getDepartmentData(): Promise<Department[]> {
  try {
    let result: any;
    const { data } = await axios.get(
      "https://services.smartrancagua.com/cdir/department"
    );
    const { rows } = data;

    result = rows.map((department: any) => {
      result = { ...department, team: department.team };
      console.log("result", result);
      return result;
    });
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const getTeamBanner = () => [
  {
    src: "/banner_corporacion.png",
    alt: "corporacion"
  }
];
