import React, { useEffect } from "react";
import { CircularProgress, Backdrop, styled } from "@mui/material";
import { initGA, logPageView } from "../../helpers/analytics";

type TransitionLoadingProps = {
  isLoading: boolean;
};

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#b73c8e",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
}));

const TransitionLoading: React.FC<TransitionLoadingProps> = ({ isLoading }) => {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  return (
    <CustomBackdrop open={isLoading}>
      <img
        src="/logo_innova/logo_blanco.svg"
        width="150px"
        alt="Centered Image"
      />
      <CircularProgress color="inherit" />
    </CustomBackdrop>
  );
};

export default TransitionLoading;
