import React, { ReactNode } from "react";
import { Typography } from "@mui/material";

interface TitleProps {
  children: ReactNode;
}

export default function Title({ children }: TitleProps) {
  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: { xs: "23px", md: "23px", lg: "47px" },
        fontWeight: "700",
      }}
    >
      {children}
    </Typography>
  );
}
