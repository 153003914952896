import React, { useRef, useEffect, useState } from "react";
//Components
import {
  Box,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
  IconButton,
  CardActions,
  Button,
  Link
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DOMPurify from "dompurify";

//Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//Providers
import { getNewsData, News } from "../../providers/news";

export default function NewsCard() {
  const [news, setNews] = useState<News[]>([]);
  useEffect(() => {
    async function fetchData() {
      const newsData = await getNewsData();
      setNews(newsData);
    }
    fetchData();
  }, []);
  const sliderRef = useRef<Slider | null>(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          vertical: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          vertical: true
        }
      }
    ]
  };

  const handleNextSlide = () => {
    sliderRef.current?.slickNext();
  };
  const formatDate = (dateString: string) => {
    const parts = dateString.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingTop: "209px",
          textAlign: "left",
          display: { xs: "none", md: "none", lg: "flex" }
        }}
      >
        <Grid
          lg={9}
          sx={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <div style={{ display: "flex" }}>
            <Typography
              variant="h1"
              sx={{
                textAlign: "left",
                fontWeight: "700",
                fontSize: "47px",
                display: { xs: "none", md: "none", lg: "flex" }
              }}
            >
              Noticias
            </Typography>
            <Box
              onClick={() => (window.location.href = "/noticias")}
              sx={{
                "&:hover": {
                  color: "#b73c8e",
                  textDecoration: "underline"
                },
                "&:active": {
                  color: "#b73c8e",
                  textDecoration: "underline"
                }
              }}
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                marginRight: "0px"
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  display: { xs: "none", md: "none", lg: "flex" }
                }}
                style={{
                  fontSize: "25px",
                  fontStyle: "normal",
                  marginRight: "10px",
                  fontWeight: "bold"
                }}
              >
                Ver más
              </Typography>
              <IconButton
                aria-label="Ver más"
                onClick={handleNextSlide}
                sx={{
                  display: { xs: "none", md: "none", lg: "flex" }
                }}
              >
                <ArrowForwardIcon
                  sx={{
                    "&:hover": {
                      color: "#b73c8e",
                      textDecoration: "underline"
                    },
                    "&:active": {
                      color: "#b73c8e",
                      textDecoration: "underline"
                    }
                  }}
                />
              </IconButton>
            </Box>
          </div>
          <Slider {...settings} ref={sliderRef}>
            {news
              .map((newsData, key) => (
                <div key={key}>
                  {/* DESKTOP */}
                  <div
                    style={{
                      padding:
                        key !== 0 && key !== news.length - 1 ? "0 10px" : ""
                    }}
                  >
                    <Link
                      href={`/noticias/${newsData.urlKey}`}
                      style={{
                        textDecoration: "none"
                      }}
                    >
                      <Card
                        sx={{
                          "&:hover": {
                            color: "#b73c8e"
                          },
                          "&:active": {
                            color: "#b73c8e"
                          },
                          maxWidth: 494,
                          minHeight: "323px",
                          border: "none !important",
                          boxShadow: "0 0 transparent",
                          borderRadius: "0",
                          display: { xs: "none", md: "none", lg: "flex" }
                        }}
                      >
                        <CardActionArea component="div" disabled disableRipple>
                          <CardMedia
                            component="img"
                            height="323"
                            image={
                              newsData.previewImage?.src ||
                              newsData.images[0].src
                            }
                            alt={
                              newsData.previewImage?.title ||
                              newsData.images[0].title
                            }
                          />
                          <CardContent sx={{ marginLeft: "-15px" }}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{
                                fontWeight: "bold"
                              }}
                            >
                              {newsData.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ textTransform: "none" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  newsData.description.length > 150
                                    ? DOMPurify.sanitize(
                                        newsData.description.slice(0, 150) +
                                          "..."
                                      )
                                    : DOMPurify.sanitize(newsData.description)
                              }}
                            ></Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </div>
                </div>
              ))
              .slice(0, 3)}
          </Slider>
        </Grid>
      </Grid>
      {/* MOBILE */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={10.5} md={10.5}>
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "700",
              fontSize: "22px",
              paddingTop: "87px",
              paddingBottom: "16px",
              display: { xs: "flex", md: "flex", lg: "none" }
            }}
          >
            Noticias
          </Typography>
        </Grid>
      </Grid>
      <Slider {...settings} ref={sliderRef}>
        {news.slice(0, 3).map((newsData, key) => (
          <div key={key}>
            <Link
              href={`/noticias/${newsData.urlKey}`}
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                  display: { xs: "block", md: "block", lg: "none" },
                  textAlign: "left",
                  minHeight: "252px"
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="252px"
                    image={newsData.previewImage?.src || newsData.images[0].src}
                    alt={
                      newsData.previewImage?.title || newsData.images[0].title
                    }
                    sx={{ paddingX: "25px" }}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "700",
                        fontSize: "22px",
                        marginLeft: "10px"
                      }}
                    >
                      {newsData.title}
                    </Typography>
                    <CardActions>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          marginLeft: "5px"
                        }}
                      >
                        {formatDate(newsData.publicationDate.substring(0, 10))}
                      </Typography>
                    </CardActions>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </div>
        ))}
      </Slider>
      <Box
        sx={{
          display: { xs: "display", md: "display", lg: "none" },
          justifyContent: "right",
          marginTop: "-144rem",
          paddingLeft: "220px",
          marginBottom: "-200px"
        }}
      >
        <Button
          href="/noticias"
          variant="outlined"
          color="inherit"
          sx={{
            border: "none",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            textTransform: "none",
            marginLeft: "-30px"
          }}
        >
          Ver más noticias <ArrowForwardIcon sx={{ scale: "90%" }} />
        </Button>
      </Box>
    </>
  );
}
